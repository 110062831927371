import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Inject, Injectable } from "@angular/core";
import {
    TegettFormControl,
    TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { CreateFormGroupArgs } from "@progress/kendo-angular-grid";
import { Observable, of } from "rxjs";
import { FormularioDevelopDto } from "../models/FormularioDevelopDto";

@Injectable()
export class FormularioDevelopTabCamposSettings implements BaseSearchSetting {

    constructor(
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private route: ActivatedRoute,
    ) {


    }

    async Load() {

        return true;
    }

    /**
     * Funcion para armar la busqueda
     */
    loadFormSearch(): FormSearch {
        return null;
    }


    /**
     * Componentes del formulario
     */
    FormComponents(dataItem: any = null): UntypedFormGroup {

        const formRecord = new UntypedFormGroup(
            {
                label: new TegettFormControl({
                    formState: dataItem?.label,
                    validatorOrOpts: [Validators.required],
                    typeField: TypeField.Input,
                    required: true,
                    nameControl: "label",
                    label: 'Label'
                }),
                nameControl: new TegettFormControl({
                    formState: dataItem?.nameControl,
                    typeField: TypeField.Input,
                    validatorOrOpts: [Validators.required],
                    required: true,
                    label: 'Campo',
                    nameControl: "nameControl",
                }),

                tipo: new TegettFormControl({
                    formState: !dataItem || !dataItem.tipo ? 'Input' : dataItem?.tipo,
                    validatorOrOpts: [Validators.required],
                    required: true,
                    nameControl: "tipo",
                    label: 'Tipo',
                    typeField: TypeField.List,
                    dataTextField: "tipo",
                    dataValueField: "idTipo",
                    funtionData: this.functionDataTipo,
                }),

                //valor por defecto
                defaultValue: new TegettFormControl({
                    formState: dataItem?.defaultValue,
                    validatorOrOpts: [],
                    required: false,
                    nameControl: "defaultValue",
                    label: 'Valor por Defecto',
                    typeField: TypeField.Input
                }),
                minDate: new TegettFormControl({
                    formState: !dataItem || !dataItem.minDate ? 'string' : dataItem.minDate,
                    validatorOrOpts: [],
                    nameControl: "minDate",
                    label: 'Fecha Mínima',
                    typeField: TypeField.List,
                    dataTextField: "textField",
                    dataValueField: "textField",
                    funtionData: [{valueField : 'fecha_actual', textField: '🗓️ (Fecha Actual)'}],
                }),

                maxDate: new TegettFormControl({
                    formState: !dataItem || !dataItem.maxDate ? 'string' : dataItem.maxDate,
                    validatorOrOpts: [],
                    nameControl: "maxDate",
                    label: 'Fecha Máxima',
                    typeField: TypeField.List,
                    dataTextField: "textField",
                    dataValueField: "textField",
                    funtionData: [{valueField : 'fecha_actual', textField: '🗓️ (Fecha Actual)'}],
                }),
 
                required: new TegettFormControl({
                    formState: dataItem?.required,
                    label: "Obligatorio",
                    typeField: TypeField.CheckBox,
                    required: false,
                    nameControl: "required",
                }),

                lookup: new TegettFormControl({
                    formState: dataItem?.lookup,
                    label: "Validar Registro Existente",
                    typeField: TypeField.CheckBox,
                    required: false,
                    nameControl: "lookup",
                }),
                lookupData: new TegettFormControl({
                    formState: dataItem?.lookupData,
                    label: "Campos a válidar",
                    typeField: TypeField.List,
                    required: false,
                    nameControl: "lookupData",
                    dataTextField: "label",
                    dataValueField: "nameControl",
                }),

                
                formato: new TegettFormControl({
                    formState: !dataItem || !dataItem.formato ? 'string' : dataItem.formato,
                    validatorOrOpts: [Validators.required],
                    nameControl: "formato",
                    label: 'Formato',
                    typeField: TypeField.List,
                    dataTextField: "formato",
                    dataValueField: "idFormato",
                    funtionData: this.functionDataFormato,
                }),


                separador: new TegettFormControl({
                    formState: !dataItem || !dataItem.separador ? 'separadorInformacionBasica' : dataItem.separador,
                    validatorOrOpts: [Validators.required],
                    required: true,
                    nameControl: "separador",
                    label: 'Separador',
                    typeField: TypeField.List,
                    dataTextField: "nombre",
                    dataValueField: "tag",
                }),

                fuenteDatos: new TegettFormControl({
                    formState: dataItem?.fuenteDatos,
                    validatorOrOpts: [],
                    required: false,
                    nameControl: "fuenteDatos",
                    label: 'Origen Fuente Datos',
                    typeField: TypeField.List,
                    dataTextField: "nombre",
                    dataValueField: "id",
                    funtionData: this.functionDataFuente
                }),
               

                dataTextField: new TegettFormControl({
                    formState: dataItem?.dataTextField,
                    validatorOrOpts: [],
                    required: false,
                    nameControl: "dataTextField",
                    label: 'Text Field',
                    typeField: TypeField.List,
                    dataTextField: "name",
                    dataValueField: "id", 
                }),


                dataValueField: new TegettFormControl({
                    formState: dataItem?.dataValueField,
                    validatorOrOpts: [],
                    required: false,
                    nameControl: "dataTextField",
                    label: 'Value Field',
                    typeField: TypeField.List,
                    dataTextField: "name",
                    dataValueField: "id" 
                }),
 


            },
            []
        );

        return formRecord;
    }

    public functionDataTipo: Observable<any[]> = of([
        { 'tipo': 'Lista', 'idTipo': 'List' },
        { 'tipo': 'Campo Texto', 'idTipo': 'Input' },
        { 'tipo': 'Chequeable', 'idTipo': 'CheckBox' },
        { 'tipo': 'Fecha', 'idTipo': 'Date' },
    ]);

    public functionDataFormato: Observable<any[]> = of([
        { 'formato': 'String', 'idFormato': 'string' },
        { 'formato': 'String (Date-Time)', 'idFormato': 'string|date-time' },
        { 'formato': 'String (Date-Span)', 'idFormato': 'string|date-span' },
        { 'formato': 'Integer (Int32)', 'idFormato': 'integer|int32' },
        { 'formato': 'Integer (Int64)', 'idFormato': 'integer|int64' },
        { 'formato': 'number', 'idFormato': 'number|double' },
        { 'formato': 'boolean', 'idFormato': 'boolean' },
    ]);




    FormEspecialidadesComponents(dataItem: any = null): UntypedFormGroup {

        const formRecord = new UntypedFormGroup(
            {

                fuenteDatos: new TegettFormControl({
                    formState: dataItem?.fuenteDatos,
                    validatorOrOpts: [Validators.required],
                    required: true,
                    nameControl: "fuenteDatos",
                    label: 'Origen Fuente Datos',
                    typeField: TypeField.List,
                    dataTextField: "nombre",
                    dataValueField: "id",
                    funtionData: this.functionDataFuente
                }),

                servicioDatos: new TegettFormControl({
                    formState: dataItem?.servicioDatos,
                    validatorOrOpts: [],
                    required: false,
                    nameControl: "servicioDatos",
                    label: 'Datos',
                    typeField: TypeField.List,
                    dataTextField: "nombre",
                    dataValueField: "id",
                    funtionData: this.functionDataServicioDatos
                }),
            },
            []
        );

        return formRecord;
    }





    public functionDataFuente: Observable<any[]> = of([
        { 'nombre': 'Api', 'id': 'Api' },
        { 'nombre': 'Servicio', 'id': 'Servicio' },
    ]);

    public functionDataServicioDatos: Observable<any[]> = of([
        { 'nombre': 'Servicio Inicial', 'id': 'Servicio Inicial' },
    ]);
}

