import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ThemeService } from '@src/app/services/Utilities/theme.service';

import sdk, { VM } from '@stackblitz/sdk';
import { FormulariosDevelopService, TemplateType } from '../../services/formulario.developer.service';
import { forkJoin, map } from 'rxjs';
import { formularioJSON } from '../../services/Formulario.store';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
  standalone: true
})
export class PlaygroundComponent implements AfterViewInit {
  @ViewChild('stackblitzContainer') stackblitzContainer: ElementRef;


  stackblitz: Promise<VM> | any;

  templateFiles: { html, ts, scss, setting, search, service, componenteModule, componenteRouting }

  themeService = inject(ThemeService);
  fomulariosDevelopeService = inject(FormulariosDevelopService);
  constructor() {
    this.LoadTemplateFiles()
  }

  ngAfterViewInit() {


  }

  get JsonData() {
    return formularioJSON()
  }


  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  /**
   * funcion para crear variable data de los select
   */
  TsTemplateFieldSelectData() {
    let tsTemplate = '';

    this.JsonData.campos
      .forEach(campo => {

        if (campo.tipo === 'List') {

          //lookupEvent = lookupEvent ? `(valueChange)=${lookupEvent}` : '';

          tsTemplate += `
            obtener${campo.nameControl}Result : any[] = [];
        `;
        }

      });

    return tsTemplate;
  }

  HtmlTemplateFields(): string {
    let htmlTemplate = '';

    this.JsonData.separadores.forEach(separador => {

      htmlTemplate += `<splus-forms-group title="${separador.nombre}" #${separador.tag}>`;

      this.JsonData.campos
        .filter(campo => campo.separador === separador.tag)
        .forEach(campo => {


          //Filtrar  los campos que tengan lookup
          const filterLookupData = this.JsonData.campos.filter(item => item.lookup === true).map(item => ({
            ...item,
            lookupData: item.lookupData.filter((data: any) => data.nameControl === campo.nameControl)
          })).filter(item => item.lookupData.length > 0);;

          let lookupEvent = '';
          if (filterLookupData.length > 0) {
            lookupEvent = `"BuscarRegistroPor${filterLookupData.find(() => true).nameControl}()" `
          }


          if (campo.tipo === 'Input') {

            const lookup = campo.lookup ? `
                <ng-template kendoTextBoxPrefixTemplate  >
                  <div class="m-1">
                    <i [ngClass]="icon" class="text-primary"></i>
                  </div>
                  <kendo-textbox-separator></kendo-textbox-separator>
                </ng-template>

                <ng-template kendoTextBoxSuffixTemplate>
                  <div *ngIf="searching${campo.nameControl}" class="k-i-loading w-5">&nbsp;</div>
                </ng-template>


              ` : ''


            if (campo.formato === 'string') {

              lookupEvent = lookupEvent ? `(blur)=${lookupEvent}` : ''
              htmlTemplate += `

                <splus-field *ngVar="f.${campo.nameControl} as ctrl" [splusFormControl]="ctrl">
                  <kendo-textbox splusInput [splusGroup]="${separador.tag}" type="text" [id]="ctrl.nameControl" ${lookupEvent} [formControl]="ctrl">
                  ${lookup}
                  </kendo-textbox>
                </splus-field>

              `;
            } else if (campo.formato === 'integer|int32' || campo.formato === 'integer|int64'  || campo.formato === 'number|double') {
              lookupEvent = lookupEvent ? `(blur)=${lookupEvent}` : ''
              htmlTemplate += `

                <splus-field *ngVar="f.${campo.nameControl} as ctrl" [splusFormControl]="ctrl">
                  <kendo-numerictextbox splusInput [splusGroup]="${separador.tag}" [id]="ctrl.nameControl" [spinners]="false" format="#" ${lookupEvent} [formControl]="ctrl">
                  ${lookup}
                  </kendo-numerictextbox>
                </splus-field>

              `;
            }
          } else if (campo.tipo === 'List') {

            lookupEvent = lookupEvent ? `(valueChange)=${lookupEvent}` : '';

            htmlTemplate += `

              <splus-field *ngVar="f.${campo.nameControl} as ctrl" [splusFormControl]="ctrl">
                <div class="flex">
                  <kendo-dropdownlist #select splusInput [splusGroup]="${separador.tag}" [formControl]="ctrl" ${lookupEvent}
                   [data]="ctrl?.funtionData | async" textField="${this.GetProperty(campo.nameControl, 'dataTextField')}" valueField="${this.GetProperty(campo.nameControl, 'dataValueField')}"
                    [valuePrimitive]="true">
                  </kendo-dropdownlist>
                </div>
                <ng-template splusFieldViewModeTemplate>
                  {{select["text"]}}
                </ng-template>
              </splus-field>

            `;
          } else if (campo.tipo === 'Date') {

            lookupEvent = lookupEvent ? `(valueChange)=${lookupEvent}` : '';

            //fecha maxima
            let maxDate = ''
            if (campo?.maxDate) {
              maxDate = `[max]="maxDate"`
            }

            //fecha minima
            let minDate = ''
            if (campo?.minDate) {
              minDate = `[min]="maxDate"`
            }
            htmlTemplate += `

              <splus-field *ngVar="f.${campo.nameControl} as ctrl" [splusFormControl]="ctrl" #fecha>
                <kendo-datepicker #calendario splusInput [navigation]="true" format="dd/MM/yyyy" ${lookupEvent}  ${maxDate} ${minDate} [formControl]="ctrl" [splusGroup]="${separador.tag}" [splusField]="fecha" [value]="ctrl.value">
                  <ng-template kendoCalendarNavigationItemTemplate let-title>
                    {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
                  </ng-template>
                </kendo-datepicker>
                <ng-template splusFieldViewModeTemplate>
                  {{calendario.value | SPlusDate}}
                </ng-template>
                <div class="hit">
                  {{!calendario.value ? '' : calendario.value | InfoDateFormat}}
                </div>
              </splus-field>

            `;
          } else if (campo.tipo === 'CheckBox') {

            lookupEvent = lookupEvent ? `(change)=${lookupEvent}` : '';

            htmlTemplate += `

                <splus-field class="ml-2 flex items-center" *ngVar="f.${campo.nameControl} as ctrl" [splusFormControl]="ctrl"
                    [viewModeDisable]="true" labelCustom="">
                    <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
                        [formControl]="ctrl" [splusGroup]="${separador.tag}" [id]="ctrl.nameControl" />

                    <ng-template splusFieldViewModeTemplate>
                        <splus-checkbox-info [checked]="ctrlCheck?.checked">
                        </splus-checkbox-info>
                    </ng-template>

                    <label [for]="processType != ProcessType.view ?  ctrl.nameControl : ''" class="select-none text-slate-500 dark:text-slate-400 text-sm"> {{ctrl?.label}}</label>
                </splus-field>


            `;
          }
        });

      htmlTemplate += `</splus-forms-group>`;
    });

    return htmlTemplate;
  }

  SearchTemplateFields(): string {
    let CamposTemplate = '';

    const nombre = this.JsonData.titulo.titulo.toLowerCase().replace(/\s+(\w)/g, (_, letter) => letter.toUpperCase());

    this.JsonData.campos
      .forEach(campo => {

        let listInfo = ''
        if (campo.tipo == 'List') {
          listInfo = `dataTextField: '${campo.dataTextField}',
              dataValueField: '${campo.dataValueField}',
          `
        }

        CamposTemplate += `
            ${campo.nameControl}: new TegettFormControl({
              formState: "",
              validatorOrOpts: [${campo.required ? 'Validators.required, ' : ''}${campo.maxDate == 'fecha_actual' ? 'Validation.MaxAgeSaludPlus, ' : ''}${campo.formato == 'integer|int64' || campo.formato == 'integer|int32' ? 'Validators.pattern(/^\d{1,250}$/), ' : ''}],
              asyncValidator: undefined,
              label: "${campo.label}",
              typeField: TypeField.${campo.tipo},
              funtionData: ${campo.tipo == 'List' ? `this.${nombre}Services.Obtener${campo.nameControl}()` : 'undefined'} ,
              required: ${campo.required},
              nameControl: "${campo.nameControl}",
              ${listInfo}
            }),
          `;
      });

    return CamposTemplate;
  }




  dedent(str: string) {
    const lines = str.split('\n');
    const minIndent = lines.filter(line => line.trim().length > 0)
      .reduce((min, line) => Math.min(min, line.match(/^(\s*)/)?.[0].length || 0), Infinity);
    return lines.map(line => line.slice(minIndent)).join('\n');
  }

  LookupFunctions() {


    const nombre = this.JsonData.titulo.titulo.toLowerCase().replace(/\s+(\w)/g, (_, letter) => letter.toUpperCase());
    const Nombre = this.capitalizeFirstLetter(nombre);

    const modulo = this.JsonData.modulo;
    const Modulo = this.capitalizeFirstLetter(this.JsonData.modulo);


    let tsTemplate = '';
    this.JsonData.campos.filter(item => item.lookup === true).forEach(item => {

      let lookupEvent = '', searchParams = '', validateEmpty = '', descriptionDialog = '';

      if (item.lookupData.length > 0) {
        validateEmpty = `//validación de campos
        if (`;
        item.lookupData.forEach((element, index) => {
          if (index > 0) {
            validateEmpty += ' || ';

            descriptionDialog += ', ';
          }
          //valiodacion campos vaciones
          validateEmpty += `!this.f.${element.nameControl}.value`;

          //parametros de busqueda
          searchParams +=
            `${element.nameControl}: this.f.${element.nameControl}.value,
          `;

          //desripcion de la alerta cuando encuentre el registro
          descriptionDialog += `El ${element.label} \${this.f.${element.nameControl}.value}`

        });
        validateEmpty += ') { return; }';

        descriptionDialog += ' ya existe en el sistema.';
      }


      lookupEvent = this.dedent(`
      searching${item.nameControl} = false;
      ${item.nameControl};
      /**
       * Buscar registro por ${item.lookupData.map(field => field.nameControl).join(', ')}
       */
       BuscarRegistroPor${item.nameControl}(){

        ${validateEmpty}

        const ${item.nameControl} = this.f.${item.nameControl}.value;

        this.searching${item.nameControl} = true;

        this.Datos${Nombre} = new ${Nombre}();
        const searchParams = {
          ${searchParams}
        };

        this.service.ValidarExistencia<${Nombre}>(searchParams).subscribe({
          next: (res) => {
            this.searching${item.nameControl} = false;
            if (res) {
              let dialogSettings = {} as SPlusDialogSettings;
              dialogSettings.title = "${Nombre} Encontrado";
              dialogSettings.description = \`${descriptionDialog}\`;
              dialogSettings.actions = [
                { text: "Cancelar" },
                { text: "Ver ${nombre}", themeColor: "primary", value: true },
              ];
              this.splusFormsDialogsService
                .Show(dialogSettings)
                .pipe(takeUntil(this.unsubcribe$))
                .subscribe((result) => {
                  if (result instanceof SPlusDialogCloseResult || !result.value) {
                    this.searching${item.nameControl} = false;
                    this.isLoading = false;
                    if (this.route.routeConfig.path !== ":id") {
                      this.f.${item.nameControl}.setValue("");
                    } else {
                      this.f.${item.nameControl}.setValue(this.${item.nameControl});
                    }
                  } else {
                    if (result.value) {
                      if (this.route.routeConfig.path === ":id") {
                        this.processType = this.ProcessType.view;
                        this.id = res.id;
                        this.LoadRecord();
                        this.router.navigate(["${Modulo}/${nombre}/" + this.id,]);
                        this.searching${item.nameControl} = false;
                      } else {
                        this.processType = this.ProcessType.view;
                        this.searching${item.nameControl} = false;
                        this.router.navigate([res.id], {
                          relativeTo: this.route,
                        });
                      }
                    }
                  }
                });
            }
          },
          error: (err) => {
            this.handleError(err, "BuscarRegistroPor${item.nameControl}()")

            this.isLoading = false;
            this.searchingDocument = false;
          },
        });

       }


       `)


      tsTemplate = lookupEvent;
    })

    return tsTemplate
  }

  LookupServiceFunctions() {
    const nombre = this.JsonData.titulo.titulo.toLowerCase().replace(/\s+(\w)/g, (_, letter) => letter.toUpperCase());
    const Nombre = this.capitalizeFirstLetter(nombre);

    const modulo = this.JsonData.modulo;
    const Modulo = this.capitalizeFirstLetter(this.JsonData.modulo);


    let tsTemplate = '';
    this.JsonData.campos
      .forEach(campo => {


        if (campo.tipo === 'List') {
          let lookupEvent = '';




          let properties = campo.properties;
          let endPoint = properties.endPoint.split('/').pop();

          lookupEvent = this.dedent(`

        /**
        * Obtener obtener ${campo.nameControl}
        * @returns Observable<SaludPlusApiResponse<T>>
        */
        Obtener${campo.nameControl}<T>(): Observable<T> {
          const record = this.http.get<SaludPlusApiResponse<T>>(
            \`\${ControllerApiList.${Modulo}.ConfiguracionConceptos}/${endPoint}\`
          ).pipe(map(response => {
            return this.resolveRequestResultService.resolve<T>(response);
          }));
          return record;
        }
       `)

          tsTemplate += lookupEvent;

        }
      })

    return tsTemplate
  }
  GetModel(Nombre) {
    let tsModelTemplate = `export class ${Nombre} implements I${Nombre} {
      id: number;
      `;

    let interfaceTemplate = `
    export interface I${Nombre} {
      id: number;
      `;

    this.JsonData.campos.forEach(item => {
      let tipo = '';
      switch (item.tipo) {
        case 'Input':
          tipo = item.formato.includes('integer') ? 'number' : 'string';
          break;
        case 'Date':
          tipo = 'Date';
          break;
        case 'List':
          tipo = item.formato.includes('integer') ? 'number' : 'string';
          break;
        case 'CheckBox':
          tipo = 'boolean';
          break;
        default:
          tipo = 'string';
      }

      tsModelTemplate += `
      ${item.nameControl}: ${tipo};`;

      interfaceTemplate += `
      ${item.nameControl}: ${tipo};`;
    });

    tsModelTemplate += `
    }`;

    interfaceTemplate += `
    }`;

    return tsModelTemplate + interfaceTemplate;
  }

  /**
   * Nombre: corresponde al nombre del componente: ejemplo Empleado,Pacientes
   * nombre: corresponde al Nombre pero minuscula.
   */
  async embedNewProject() {

    var regex = /\s+(\w)?/gi;
    const nombre = this.JsonData.titulo.titulo.toLowerCase().replace(regex, function (match, letter) {
      return letter.toUpperCase();
    });

    const Nombre = this.capitalizeFirstLetter(nombre);

    const modulo = this.JsonData.modulo;//ej:facturacion
    const Modulo = this.capitalizeFirstLetter(this.JsonData.modulo);//ej:Facturacion

    const icon = this.JsonData.titulo.icono;

    let html = '', ts = '', tsService = '';
    const htmlTemplate = this.HtmlTemplateFields();
    this.templateFiles.html = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.html, '@{nombre}', this.JsonData.titulo.titulo);
    this.templateFiles.html = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.html, '@{Nombre}', Nombre);

    html = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.html, '@{template-fields}', htmlTemplate);

    //TS
    let tsTemplate = this.LookupFunctions() //
    this.templateFiles.ts = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.ts, '@{Nombre}', Nombre);
    this.templateFiles.ts = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.ts, '@{nombre}', nombre);
    this.templateFiles.ts = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.ts, '@{Modulo}', Modulo);
    this.templateFiles.ts = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.ts, '@{modulo}', modulo);

    ts = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.ts, '//@{lookupFunctions}', tsTemplate);


    //settings
    const settingTemplate = this.SearchTemplateFields()
    this.templateFiles.setting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.setting, '@{Campos}', settingTemplate);
    this.templateFiles.setting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.setting, '@{nombre}', nombre);
    this.templateFiles.setting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.setting, '@{Nombre}', Nombre);


    //search
    this.templateFiles.search = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.search, '@{nombre}', nombre);
    this.templateFiles.search = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.search, '@{Nombre}', Nombre);

    //servicio
    let tsServicioTemplate = this.LookupServiceFunctions()
    this.templateFiles.service = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.service, '@{nombre}', nombre);
    this.templateFiles.service = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.service, '@{Nombre}', Nombre);
    this.templateFiles.service = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.service, '@{Modulo}', Modulo);

    tsService = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.service, '//@{Funciones}', tsServicioTemplate);


    //component modulo
    this.templateFiles.componenteModule = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteModule, '@{nombre}', nombre);
    this.templateFiles.componenteModule = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteModule, '@{Nombre}', Nombre);
    this.templateFiles.componenteModule = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteModule, '@{Modulo}', Modulo);

    //component routing
    this.templateFiles.componenteRouting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteRouting, '@{nombre}', nombre);
    this.templateFiles.componenteRouting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteRouting, '@{Nombre}', Nombre);
    this.templateFiles.componenteRouting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteRouting, '@{modulo}', modulo);
    this.templateFiles.componenteRouting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteRouting, '@{Modulo}', Modulo);
    this.templateFiles.componenteRouting = this.fomulariosDevelopeService.SetValueTemplate(this.templateFiles.componenteRouting, '@{icon}', icon);

    //TODO cambiarlo por el modelo original
    const modelo = this.GetModel(Nombre)


    const root = ``
    //const root = `src/app/pages/SaludPlus/${Modulo}/`

    this.stackblitz = sdk.embedProject(this.stackblitzContainer.nativeElement, {
      title: 'SaludPlus Playground',
      description: 'SplusForms Playground',
      template: 'typescript',
      files: {
        //componente
        [`${root}${nombre}/components/${nombre}.component.html`]: `${html}`,
        [`${root}${nombre}/components/${nombre}.component.scss`]: ``,
        [`${root}${nombre}/components/${nombre}.component.ts`]: `${ts}`,

        // config
        [`${root}${nombre}/config/${nombre}.settings.ts`]: `${this.templateFiles.setting}`,
        [`${root}${nombre}/config/${nombre}.search.ts`]: `${this.templateFiles.search}`,

        // servicios
        [`${root}${nombre}/services/${nombre}.service.ts`]: `${tsService}`,

        //modelo
        [`models/${Modulo}/${Nombre}.model.ts`]: `${modelo}`,


        // pagina modulo
        [`${root}${nombre}/${nombre}.module.ts`]: `${this.templateFiles.componenteModule}`,
        [`${root}${nombre}/${nombre}.routing.ts`]: `${this.templateFiles.componenteRouting}`,

        // modulo
        [`${root}${modulo}.module.ts`]: ``,
        [`${root}${modulo}.routing.ts`]: ``
      },
      dependencies: {
      },
      settings: {
        compile: { clearConsole: false },
      },

    }, {
      theme: this.themeService.isDarkModeMode() ? 'dark' : 'light',
      clickToLoad: false,
      hideExplorer: false,
      hideNavigation: false,
      sidebarView: 'project',
      showSidebar: true,
      view: 'editor',
      hideDevTools: false,
      openFile: `${root}${nombre}/components/${nombre}.component.html,${root}${nombre}/components/${nombre}.component.ts,`
    });



  }


  /**
   *
   * @param nameControl nombre del control
   * @param property propiedad a leer
   * @param level nivel de lectura. si es vacio entonces leera en la raiz. usar : para el nivel del obj. padre:hijo:nieto
   * @returns valor de la propieda.
   */
  GetProperty(nameControl: string, property: string, level = '') {
    let campo = this.JsonData.campos.find(s => s.nameControl == nameControl);

    if (campo) {
      if (level) {
        let levels = level.split(':');
        let current = campo;

        for (let l of levels) {
          if (current[l]) {
            current = current[l];
          } else {
            return null; // Si algún nivel no existe, devolvemos null
          }
        }

        return current[property] ? current[property] : null;
      } else {
        return campo[property] ? campo[property] : null;
      }
    }

    return null;
  }

  LoadTemplateFiles() {

    const htmlRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.HTML);
    const tsRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.TS);
    const scssRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.SCSS);
    const settingRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.SETTING);
    const searchRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.SEARCH);
    const serviceRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.SERVICE);
    const componenteModuleRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.COMPONENTE_MODULE);
    const componenteRoutingRequest = this.fomulariosDevelopeService.obtenerTemplate(TemplateType.COMPONENTE_ROUTING);

    forkJoin([htmlRequest, tsRequest, scssRequest, settingRequest, searchRequest, serviceRequest, componenteModuleRequest, componenteRoutingRequest])
      .pipe(map(([html, ts, scss, setting, search, service, componenteModule, componenteRouting]) => ({ html, ts, scss, setting, search, service, componenteModule, componenteRouting })))
      .subscribe(templateFiles => {
        this.templateFiles = templateFiles;
        this.embedNewProject()

      });
  }

  async openFiles() {
    if (!this.stackblitz) {
      console.error('SDK stackblitz is not available');
      return;
    }

    await this.stackblitz.editor.openFile(['index.html', 'index.js']);
  }

  async writeToFiles() {
    if (!this.stackblitz) {
      console.error('SDK vm is not available');
      return;
    }

    const files = await this.stackblitz.getFsSnapshot();
    const html = files ? files['index.html'] : '';
    const js = files ? files['index.js'] : '';
    const time = new Date().toTimeString();

    await this.stackblitz.applyFsDiff({
      create: {
        'index.html': ` datos`,
        'index.js': ` datos 2`,
      },
      destroy: [],
    });
  }


  async embedNewProjectId() {
    const projectId = 'stackblitz-splus-playground'
    this.stackblitz = sdk.embedProjectId(this.stackblitzContainer.nativeElement, projectId,
      {
        theme: this.themeService.isDarkModeMode() ? 'dark' : 'light',
        clickToLoad: false,
        hideExplorer: false,
        hideNavigation: false,
        sidebarView: 'project',
        showSidebar: true,
        view: 'default',
        hideDevTools: false,
      });
  }

  async embedNewProjectEJEMPLO() {


    this.stackblitz = sdk.embedProject(this.stackblitzContainer.nativeElement, {
      title: 'SaludPlus Playground',
      description: 'SplusForms Playground',
      template: 'typescript',
      files: {
        'src/main.ts': 'console.log("Hello World!");',
        'src/app/app.component.ts': `
        import { Component } from '@angular/core';

        @Component({
          selector: 'app-root',
          template: '<h1>Hello from Angular!</h1>',
        })
        export class AppComponent {}
      `,
        'src/app/app.module.ts': `
        import { NgModule } from '@angular/core';
        import { BrowserModule } from '@angular/platform-browser';
        import { AppComponent } from './app.component';

        @NgModule({
          declarations: [AppComponent],
          imports: [BrowserModule],
          bootstrap: [AppComponent],
        })
        export class AppModule {}
      `,
        'angular.json': JSON.stringify({
          "version": 1,
          "projects": {
            "demo": {
              "projectType": "application",
              "sourceRoot": "src",
              "architect": {
                "build": {
                  "builder": "@angular-devkit/build-angular:browser",
                  "options": {
                    "outputPath": "dist/demo",
                    "index": "src/index.html",
                    "main": "src/main.ts",
                    "polyfills": "src/polyfills.ts",
                    "tsConfig": "tsconfig.app.json",
                    "assets": ["src/favicon.ico", "src/assets"],
                    "styles": ["src/styles.css"],
                    "scripts": []
                  }
                }
              }
            }
          }
        }),
        'tsconfig.app.json': JSON.stringify({
          "compilerOptions": {
            "outDir": "./dist/out-tsc",
            "types": []
          },
          "files": [
            "src/main.ts",
            "src/polyfills.ts"
          ],
          "include": [
            "src/**/*.d.ts"
          ]
        }),
        'src/index.html': '<!doctype html><html><head><title>Angular</title><meta charset="utf-8"></head><body><app-root></app-root></body></html>',
        'src/styles.css': '',
        'src/polyfills.ts': 'import "zone.js";',
      },
      dependencies: {
        '@angular/common': '*',
        '@angular/compiler': '*',
        '@angular/core': '*',
        '@angular/forms': '*',
        '@angular/platform-browser': '*',
        '@angular/platform-browser-dynamic': '*',
        'rxjs': '*',
        'zone.js': '*',
      },
      settings: {
        compile: { clearConsole: false },
      },

    }, {
      theme: this.themeService.isDarkModeMode() ? 'dark' : 'light',
      clickToLoad: false,
      hideExplorer: false,
      hideNavigation: false,
      sidebarView: 'project',
      showSidebar: true,
      view: 'default',
      hideDevTools: false,
    });



  }

}
