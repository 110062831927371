<splus-forms [hidden]="build || generar" id="empleadosPage" [submit]="submitted" [messageProcess]="messageProcess"
    [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">



    <form #myForm="ngForm" [formGroup]="FormRecord" (ngSubmit)="onSubmit(myForm)" autocomplete="off"
        [ngClass]="{'formGroup-read': processType == ProcessType.view}">



        <splus-forms-header [isLoading]="isLoading">
            <splus-forms-main-title [title]="'Formularios'"
                [titleData]="processType == ProcessType.view ? nombreCompleto : 'Fomularios'" [icon]="icon">
            </splus-forms-main-title>

            <div *ngIf="processType == ProcessType.view || processType == ProcessType.edit">
                <div class="sub-title-info ">
                    {{this.FormRecord.value.nombreFormulario | uppercase }}

                    <div *ngIf="isLoading">

                        <div class="k-hbox">
                            <kendo-skeleton shape="text" animation="pulse" width="100px"></kendo-skeleton> &nbsp;
                            <kendo-skeleton shape="text" animation="pulse" width="70px"></kendo-skeleton> &nbsp;
                            <kendo-skeleton shape="text" animation="pulse" width="90px"></kendo-skeleton>
                        </div>
                    </div>


                </div>
            </div>


            <splus-forms-toolbar [options]="options">
                <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

                    <button type="Submit" [disabled]="isLoading"
                        *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton
                        rounded="small" themeColor="primary">
                        <i class="fa-solid fa-floppy-disk"></i> Guardar
                    </button>

                    <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
                        rounded="small" themeColor="primary" (click)="goEdit()">
                        <i class="fa-solid fa-floppy-disk"></i>Editar
                    </button>

                    <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="cancelEdit()">
                        Cancelar
                    </button>

                    <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="goback()">
                        Volver
                    </button>

                    <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="clearForm()">
                        Limpiar
                    </button>


                    <button [disabled]="isLoading" kendoButton rounded="small" themeColor="base" type="button"
                        (click)="Generar()">
                        <i class="fa-regular fa-folder-arrow-down text-primary"></i> <span class="text-primary">
                            Generar</span>
                    </button>


                    <button [disabled]="isLoading" kendoButton rounded="small" themeColor="base" type="button"
                        (click)="GoBuild()">
                        <i class="fa-duotone fa-dice-d6 text-primary"></i> <span class="text-primary"> Vista
                            Previa</span>
                    </button>



                </splus-forms-toolbar-buttons>
            </splus-forms-toolbar>


        </splus-forms-header>

        <splus-forms-fields [processType]="processType">

            <splus-forms-group [title]="'Información Básica'" #grupoBasico>

                <splus-field *ngVar="f.nombreFormulario as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox splusInput (blur)="nombreFormularioBlur($event)" [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl"> </kendo-textbox>
                </splus-field>

                <splus-field *ngVar="f.idTipo as ctrl" [splusFormControl]="ctrl">
                    <div class="flex">
                        <div>
                            <kendo-dropdownlist #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
                                [data]="obtenerTiposResult" [textField]="'tipo'" [valueField]="'idTipo'" (valueChange)="formType()"
                                [valuePrimitive]="true">
                            </kendo-dropdownlist>
                        </div>
                        <div>
                            <button type="button" kendoButton (click)="windowOpenedCampo = true" class="ml-3">
                                <i class=" text-primary fa-duotone fa-comet"></i> Importar Modelo
                            </button>
                        </div>




                    </div>
                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>


            </splus-forms-group>


            <splus-forms-group title="Personalización" #grupoPers>

                <splus-field *ngVar="f.icono as ctrl" [splusFormControl]="ctrl">
                    <kendo-textbox  (blur)="IconBlur($event)"  splusInput [splusGroup]="grupoPers" type="text" [id]="ctrl.nameControl"
                        [formControl]="ctrl"> </kendo-textbox>
                </splus-field>


                <splus-field *ngVar="f.modulo as ctrl" [splusFormControl]="ctrl">
                    <kendo-dropdownlist splusInput [splusGroup]="grupoPers" [formControl]="ctrl"
                        [data]="obtenerModulosResult" [textField]="'modulo'" [valueField]="'idModulo'"
                        [valuePrimitive]="true" >
                    </kendo-dropdownlist>
                </splus-field>


            </splus-forms-group>


            <splus-tabs [keepTabContent]='true'>
                <splus-tab title="Inicio" [selected]="true">
                    <ng-template splusTabContent>

                        <kendo-splitter>
                            <kendo-splitter-pane size="250px" min="250px">

                                <splus-tabs [keepTabContent]="true" tabPosition="bottom">
                                    <splus-tab title="Página" [selected]="true">
                                        <ng-template splusTabContent>
                                            <kendo-treeview [nodes]="FormDev" textField="text"
                                                kendoTreeViewHierarchyBinding [isSelected]="isItemSelected"
                                                (selectionChange)="onSelectionChange($event,$event)"
                                                childrenField="items" kendoTreeViewExpandable expandBy="id"
                                                [expandedKeys]="['0','1']">

                                                <ng-template kendoTreeViewNodeTemplate let-dataItem>

                                                    <span [ngSwitch]="dataItem.id">

                                                        <i *ngSwitchCase="'0'"
                                                            [class]="dataItem.icon + ' text-lg text-orange-500'"></i>

                                                        <i *ngSwitchCase="'1'"
                                                            class="fa-regular fa-memo-pad text-lg text-green-600"></i>
                                                        <i *ngSwitchCase="'2'"
                                                            class="fa-regular fa-memo-pad text-lg text-primary"></i>
                                                        <i *ngSwitchCase="'3'"
                                                            class="fa-light fa-table-cells text-lg text-primary"></i>

                                                        <span *ngSwitchDefault>

                                                            <span [ngSwitch]="dataItem.type">

                                                                <!--Campo normal-->
                                                                <i *ngIf="dataItem?.type == 'Input' && dataItem?.format == 'string'"
                                                                    class="fa-duotone fa-input-text text-lg text-primary"></i>


                                                                <i style="--fa-secondary-color: #ff1100;"
                                                                    *ngIf="dataItem?.type == 'Input' && (dataItem?.format == 'integer|int32' || dataItem?.format == 'integer|int64' || dataItem?.format == 'number|double') "
                                                                    class="fa-duotone fa-input-numeric text-lg text-primary"></i>


                                                                <i *ngIf="dataItem?.type == 'CheckBox' && dataItem?.format == 'boolean'"
                                                                    class="fa-duotone fa-square-check text-lg text-primary"></i>

                                                                <i *ngIf="dataItem?.type == 'List' "
                                                                    class="fa-duotone fa-list-dropdown text-lg text-primary"
                                                                    style="--fa-secondary-color: #e19a00;"></i>

                                                                <i *ngIf="dataItem?.type == 'Date' "
                                                                    class="fa-solid fa-calendar-days text-lg text-primary"></i>

                                                            </span>


                                                        </span>


                                                    </span>

                                                    {{ dataItem.text }}
                                                </ng-template>
                                                <ng-template kendoTreeViewDragClueTemplate let-action="action"
                                                    let-destinationItem="destinationItem" let-text="text">
                                                    <span class="k-drag-status k-font-icon k-icon"></span>
                                                    <span>{{ text }}</span>
                                                </ng-template>


                                            </kendo-treeview>
                                        </ng-template>
                                    </splus-tab>

                                    <splus-tab title="Archivos">
                                        <ng-template splusTabContent>
                                            <kendo-treeview [nodes]="FormFiles" textField="text"
                                                kendoTreeViewHierarchyBinding [isSelected]="isFileItemSelected"
                                                (selectionChange)="onSelectionChange($event,$event)"
                                                childrenField="items" kendoTreeViewExpandable expandBy="id"
                                                [expandedKeys]="['0','1']" class="min-h-96">

                                                <ng-template kendoTreeViewNodeTemplate let-dataItem>

                                                    <span [ngSwitch]="dataItem.id">

                                                        <i *ngSwitchCase="'0'"
                                                            [class]="dataItem.icon + ' text-lg text-red-500'"></i>

                                                        <i *ngSwitchCase="'1'"
                                                            [class]="dataItem.icon + ' text-lg text-slate-700'"></i>

                                                        <i *ngSwitchCase="'2'"
                                                            class="fa-regular fa-memo-pad text-lg text-primary"></i>
                                                        <i *ngSwitchCase="'3'"
                                                            class="fa-light fa-table-cells text-lg text-primary"></i>

                                                        <span *ngSwitchDefault>

                                                            <span [ngSwitch]="dataItem.type">

                                                                <!--Campo normal-->
                                                                <i *ngIf="dataItem?.type == 'Input' && dataItem?.format == 'string'"
                                                                    class="fa-duotone fa-input-text text-lg text-primary"></i>


                                                                <i style="--fa-secondary-color: #ff1100;"
                                                                    *ngIf="dataItem?.type == 'Input' && (dataItem?.format == 'integer|int32' || dataItem?.format == 'integer|int64') "
                                                                    class="fa-duotone fa-input-numeric text-lg text-primary"></i>


                                                                <i *ngIf="dataItem?.type == 'CheckBox' && dataItem?.format == 'boolean'"
                                                                    class="fa-duotone fa-square-check text-lg text-primary"></i>

                                                                <i *ngIf="dataItem?.type == 'List' "
                                                                    class="fa-duotone fa-list-dropdown text-lg text-primary"
                                                                    style="--fa-secondary-color: #e19a00;"></i>

                                                                <i *ngIf="dataItem?.type == 'Date' "
                                                                    class="fa-solid fa-calendar-days text-lg text-primary"></i>

                                                            </span>


                                                        </span>


                                                    </span>

                                                    {{ dataItem.text }}
                                                </ng-template>
                                                <ng-template kendoTreeViewDragClueTemplate let-action="action"
                                                    let-destinationItem="destinationItem" let-text="text">
                                                    <span class="k-drag-status k-font-icon k-icon"></span>
                                                    <span>{{ text }}</span>
                                                </ng-template>


                                            </kendo-treeview>
                                        </ng-template>
                                    </splus-tab>
                                </splus-tabs>




                            </kendo-splitter-pane>


                            <kendo-splitter-pane>

                                <campo-formulario-develop *ngIf="treeSeleted?.render === 'field' && this.treeSeleted "
                                    (dataChange)="campoChange($event)"
                                    [(dataCampo)]="dataSeleted"></campo-formulario-develop>


                                <tab-campos-formulario-develop
                                    *ngIf="treeSeleted?.render === 'root' && this.treeSeleted "
                                    (DataEvent)="treeReload()"></tab-campos-formulario-develop>

                            </kendo-splitter-pane>
                            <ng-template #noCampo>
                            </ng-template>
                        </kendo-splitter>

                    </ng-template>
                </splus-tab>


                <splus-tab title="Separadores">
                    <ng-template splusTabContent>
                        <tab-separador-formulario-develop></tab-separador-formulario-develop>
                    </ng-template>
                </splus-tab>



            </splus-tabs>

        </splus-forms-fields>

    </form>



</splus-forms>

<kendo-dialog class="kendo-dialog-resultado" title="Obtener modelo" *ngIf="windowOpenedCampo" maxWidth="95%"
    minWidth="50%" maxHeight="95%" minHeight="400px" (close)="windowOpenedCampo = false">
    <div>
        <app-infraestructura-api-develop (dataChange)="ApiChange($event)"></app-infraestructura-api-develop>
    </div>
</kendo-dialog>



<div *ngIf="build">
    <div>
        <div class="text-center">
            <button [disabled]="isLoading" kendoButton rounded="small" themeColor="base" type="button"
                (click)="build = false">
                <i class="fa-duotone fa-dice-d6 text-primary dark:text-white"></i> <span
                    class="text-primary dark:text-white"> Salir Vista Previa</span>
            </button>
            <div class="border-b pb-2"></div>
        </div>


        <app-build-formularios-develop></app-build-formularios-develop>
    </div>
</div>

<div *ngIf="generar">
    <div class="h-full">
        <div class="text-center">
            <button [disabled]="isLoading" kendoButton rounded="small" themeColor="base" type="button"
                (click)="generar = false">
                <i class="fa-duotone fa-dice-d6 text-primary dark:text-white"></i> <span
                    class="text-primary dark:text-white"> Salir Playgroud</span>
            </button>
            <div class="border-b pb-2"></div>
        </div>

        <app-playground></app-playground>
    </div>
</div>



<div class="border-t pt-2">
    <kendo-switch [(ngModel)]="log" onLabel="Log" offLabel="Log"></kendo-switch>
    <pre *ngIf="log" class="dark:text-white">
    {{JsonData | json}}
</pre>
</div>