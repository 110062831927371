import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Inject, Injectable } from "@angular/core";
import {
    TegettFormControl,
    TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { CreateFormGroupArgs } from "@progress/kendo-angular-grid"; 
import { SeparadorFormularioDevelopDto } from "../models/SeparadorFormularioDevelopDto";

@Injectable()
export class FormularioDevelopTabSeparadorSettings implements BaseSearchSetting {
 
    constructor(
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private route: ActivatedRoute, 
    ) {

        
    }

    async Load() {

        return true;
    }

    /**
     * Funcion para armar la busqueda
     */
    loadFormSearch(): FormSearch {
        return null;
    }
 

    /**
     * Componentes del formulario
     */
    FormComponents(args: CreateFormGroupArgs = null): UntypedFormGroup {
       
        const dataItem = args != null && args.isNew ? new SeparadorFormularioDevelopDto() : args?.dataItem;

        const formRecord = new UntypedFormGroup(
            {
                nombre: new TegettFormControl({ 
                    formState: dataItem?.nombre,
                    validatorOrOpts: [Validators.required],
                    typeField: TypeField.Input,
                    required: true,
                    nameControl: "nombre",
                    label : 'Separador'
                }),
                tag: new TegettFormControl({ 
                    formState: dataItem?.nombre,
                    validatorOrOpts: [],
                    typeField: TypeField.Input,
                    nameControl: "tag",
                    label : 'Tag'
                }) 
            },
            []
        );

        return formRecord;
    }
    
 
}

