import { Component, OnInit, ViewChild, OnDestroy, signal, inject } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
} from "@angular/router";
import { Subject, forkJoin } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { Title } from "@angular/platform-browser";
import { Messages } from "src/app/models/General/Message.model";
import { RoutingState } from "src/app/services/Generals/routing.state.service";
import { UtilService } from "src/app/services/Utilities/util.service";
import { PersistenceService } from "src/app/services/Utilities/data.persistence.service";
import { IntlService } from "@progress/kendo-angular-intl";
import { ComboBoxComponent } from "@progress/kendo-angular-dropdowns";
import { AuthenticationService } from "@src/app/services/Security/authentication.service";
import { FormularioDevelopSettings } from "../config/formularios.develop.settings";
import {
  ProcessType,
  SPlusDialogCloseResult,
  SPlusDialogSettings,
  SPlusFormsDialogsService,
  SPlusMoreOption,
  SPlusOption,
  SPlusProcessMessageComponent,
} from "@saludplus/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { Empleados } from "@src/app/models/Nomina/Empleados.model";
import { FormulariosDevelopService, TemplateType } from "../services/formulario.developer.service";
import { FormularioDevelopDto } from "../models/FormularioDevelopDto";
import { TabCamposFormularioDevelopComponent } from "../shared/tab-campos-formulario-develop/tab-campos-formulario-develop.component";
import { BuildFormulariosDevelopComponent } from "../shared/build-formulario-develop/build-formularios-develop.component";
import { TreeItem } from "@progress/kendo-angular-treeview";
import { formularioJSON } from "../services/Formulario.store";

@Component({
  selector: "app-formularios-develop",
  templateUrl: "./formularios-develop.component.html",
  styleUrls: ["./formularios-develop.component.scss"],
})
export class FormulariosDevelopComponent extends SplusBaseForm implements OnInit, OnDestroy {
  public override isNaN = isNaN;
  override id = 0;
  override submitted = false;
  override isLoading = false;
  override isDeleting = false;
  maxDate = new Date();
  nombreCompleto = "";
  // Forms
  override FormRecord: UntypedFormGroup;
  empleado: Empleados = new Empleados();
  DatosEmpleado = new Empleados();
  emptyFields: Messages = new Messages();

  // Forms - control de los estados
  override processType: ProcessType = ProcessType.create;
  override ProcessType = ProcessType;
  override showMessageProcess: boolean;
  override messageProcess = new SPlusProcessMessageComponent();

  obtenerTiposResult: any[] = [{ 'tipo': 'Formulario', 'idTipo': 1 }, { 'tipo': 'Reporte', 'idTipo': 2 }];

  obtenerModulosResult: any[] = [
    { 'modulo': 'Administracion', 'idModulo': 'administracion' },
    { 'modulo': 'Facturacion', 'idModulo': 'facturacion' },
    { 'modulo': 'Citas', 'idModulo': 'citas' },
    { 'modulo': 'HistoriasClinicas', 'idModulo': 'historias-clinicas' },
    { 'modulo': 'Triage', 'idModulo': 'triage' },
    { 'modulo': 'Asistencial', 'idModulo': 'asistencial' },
    { 'modulo': 'Inventario', 'idModulo': 'inventario' },
    { 'modulo': 'Auditoria', 'idModulo': 'auditoria' },
    { 'modulo': 'ImagenesDiagnosticas', 'idModulo': 'imagenes-diagnosticas' },
    { 'modulo': 'Laboratorio', 'idModulo': 'laboratorio' },
    { 'modulo': 'Calidad', 'idModulo': 'calidad' },
    { 'modulo': 'Contabilidad', 'idModulo': 'contabilidad' },
    { 'modulo': 'Presupuesto', 'idModulo': 'presupuesto' },
    { 'modulo': 'Nomina', 'idModulo': 'nomina' },
  ];

  swaggerJson: any
  override icon = "";
  build = false;
  generar = false;


  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/developer/formularios/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearForm",
      showIn: ProcessType.view,
      text: "Crear nuevo formulario",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarForm",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];
  log = false

  private unsubcribe$ = new Subject<void>();
  //tabs form
  public formGroupSettingTabCampos: FormGroup;


  @ViewChild("selectGrupos") public comboGrupos: ComboBoxComponent;
  @ViewChild(TabCamposFormularioDevelopComponent) tabCampos!: TabCamposFormularioDevelopComponent;


  public FormDev: any[] = [];
  public FormFiles: any[] = [];

  private sPlusFormsDialogsService = inject(SPlusFormsDialogsService);

  constructor(
    private formularioDevelopSettings: FormularioDevelopSettings,
    private fomulariosDevelopeService: FormulariosDevelopService
  ) {
    super()

    this.controlProcess();

    this.routingState.loadRouting();
    this.router.events.pipe(takeUntil(this.unsubcribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.controlProcess();
      }
    });



  }


  get JsonData() {
    return formularioJSON()
  }

  GoBuild() {
    this.build = true;
  }


  Generar() {

    this.generar = true;


  }




  jsonGrupo: any;
  treeSeleted: any;
  dataSeleted: any;

  ngOnInit() {
    this.id = this.route.snapshot.params["id"];
    this.icon = this.route.snapshot.data["icon"];
    this.titleService.setTitle(this.route.snapshot.data["title"]);


    this.loadFormComponents();

    this.isLoading = true;
    this.FormRecord.disable();

    this.After();

    this.FormRecord.valueChanges.subscribe(values => { 
      this.jsonGrupo = values;
      //this.treeReload();
    })
    //  this.FormRecord.addValidators(this.ObtenerJson());
    this.treeReload()
  }


  nombreFormularioBlur(event) { 
    this.JsonData.titulo.titulo = this.f.nombreFormulario.value;
  }
  IconBlur(event) { 
    this.JsonData.titulo.titulo = this.f.icono.value;
  }
    
  public selectedKeys: any[] = ["0_0"];

  public isItemSelected = (_: any, index: string) => this.selectedKeys.indexOf(index) > -1;
  public isFileItemSelected = (_: any, index: string) => this.selectedKeys.indexOf(index) > -1;

  public onSelectionChange({ index }: any, event: TreeItem): void {

    this.treeSeleted = null;

    setTimeout(() => {
      this.selectedKeys = [index];
      this.treeSeleted = event.dataItem;
      this.dataSeleted = this.JsonData.campos.find(s => s.nameControl == event.dataItem.text)
    }, 0);


  }

  treeReload() {

    let campos = [];

    if (!this.JsonData || !this.JsonData.campos) return;

    this.JsonData.campos.forEach(element => {

      const campo = {
        id: `0_${element.formato.toLowerCase()}`,
        text: element.nameControl,
        render: 'field',
        type: element.tipo,
        format: element.formato
      }
      campos.push(campo);
    });

    let FormDev: any[] = [
      {
        id: '0',
        render: 'root',
        text: this.JsonData.titulo.titulo,
        icon: this.JsonData.titulo.icono,
        items: [
          {
            id: '1',
            text: "Creación",
            render: 'create',
            items: campos,
          },
          {
            id: '2',
            text: "Edición",
            render: 'edit',
            items: campos,
          },
          {
            id: '3',
            text: "Busqueda",
            items: [
            ],
          },
        ],
      },
    ];

    this.FormDev = FormDev;



    this.FormFiles = [
      {
        id: '0',
        render: 'root',
        text: 'pages',
        icon: 'fa-solid fa-folder-bookmark',
        items: [
          {
            id: '1',
            text: "SaludPlus",
            render: 'create',
            icon: 'fa-duotone fa-folder-grid',
            items: [

              {
                id: '2',
                text: "Edición",
                render: 'edit',
                items: campos,
              },
              {
                id: '3',
                text: "Busqueda",
                items: [
                ],
              },
            ],
          },
        ],
      },
    ];


  }


  /**
   * Determina el estado de la pantalla (insercion, vista, edicion, eliminacion)
   */
  controlProcess() {
    const params = this.util.decodeURLParams(this.router.url);
    const mode = params.mode;

    if (this.route.routeConfig.path === ":id") {
      if (mode === "e") {
        this.processType = ProcessType.edit;
      } else {
        this.processType = ProcessType.view;
      }
      this.showMessageProcess = mode === "i" || mode === "u";

      this.messageProcess.title = "Confirmación";
      this.messageProcess.message = "Empleado guardado exitosamente";
      this.messageProcess.type = "success";
      if (mode === "df") {
        this.processType = ProcessType.view;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el Empleado porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    } else {
      if (mode === "d") {
        this.processType = ProcessType.create;
        this.messageProcess.title = "Confirmación";
        this.messageProcess.message = "Empleado Eliminado exitosamente";
        this.messageProcess.type = "success";

        this.showMessageProcess = true;
      }

      if (mode === "df") {
        this.processType = ProcessType.edit;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el Empleado porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    }
  }

  /**
   * Abreviacion para acceder a los controles del FormRecord
   */
  public get f(): any {
    return this.FormRecord.controls;
  }

  /**
   * Componentes del formulario
   */
  loadFormComponents() {
    let data = {
      icono: this.JsonData.titulo.icono,
      nombreFormulario: this.JsonData.titulo.titulo,
      modulo: this.JsonData.modulo,
      idTipo: this.JsonData.tipoformulario
    };
    this.FormRecord = this.formularioDevelopSettings.FormComponents(data);
  }
  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  formType(){
    if(this.f.idTipo == 1){

    }else if(this.f.idTipo == 2){

    }
  }
  /**
   * Guardar o actualizar el registro
   */
  async onSubmit(event) {
    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        //   this.UpdateRecord();
      } else {
        //   this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }

  /**
   * Guardar
   */
  SaveRecord() {
    this.fomulariosDevelopeService
      .Guardar<number>(this.FormRecord.value)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });
          }

        }
      });
  }

  /**
   * Actualizar
   */
  UpdateRecord() {
    this.isLoading = true;
    this.submitted = true;
    if (this.FormRecord.valid && !this.FormRecord.pristine) {
      this.fomulariosDevelopeService
        .actualizarRegistro<boolean>(this.FormRecord.value, this.id)
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            if (res) {
              this.router.navigate([], {
                queryParams: { mode: "u" },
                queryParamsHandling: "merge",
              });
            }


          }
        });
    } else {
      this.isLoading = false;
    }
  }
  /**
   * Eliminar
   */
  async DeleteRecord() {
    this.isDeleting = true;
    this.isLoading = true;
    this.fomulariosDevelopeService.Delete<boolean>(this.id).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res.isSuccessful) {
          this.isLoading = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        } else if (res.isError) {
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Error eliminar empleado";
          dialogSettings.description = res.errorMessage;
          dialogSettings.actions = [{ text: "Cerrar" }];
          this.sPlusFormsDialogsService.Show(dialogSettings);
          this.isLoading = false;
        } else {
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Error eliminar empleado";
          dialogSettings.description = res.messages.join("\n");
          //dialogSettings.actions = [{ text: "Cerrar" }];
          this.sPlusFormsDialogsService.Show(dialogSettings);
          this.isLoading = false;
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Error eliminar empleado";
        dialogSettings.description =
          "Hubo un error al intentar eliminar el empleado por favor intentelo mas tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.sPlusFormsDialogsService.Show(dialogSettings);
        this.isLoading = false;
      },
    });
  }

  /**
   * Abre alerta para eliminar registro
   */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar empleado";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar este empleado?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.sPlusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }

  /**
   * Limpiar formulario
   */
  clearForm() {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    // Valores por defecto
    this.SetDefaultValues();
    this.submitted = false;


    this.swaggerJson = ''

    this.JsonData['campos'] = [];

    this.treeReload();
  }
  /**
   * Setea valores por defecto
   */
  SetDefaultValues() {
  }





  /**
   * formulario en modo edicción
   */
  goEdit() {
    this.routingState.goEdit();
  }

  /**
   * Cancelar insercion o edición
   */
  cancelEdit() {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.sPlusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }

 

  /**
   * Cargar el registro por su id
   */
  LoadRecord() {

    this.isLoading = true;
    this.fomulariosDevelopeService.BuscarEmpleado<Empleados>(this.id).subscribe({
      next: (res) => {
        this.isDeleting = false;
        if (res.isSuccessful) {
          res.result.fechaNacimiento = this.intl.parseDate(
            res.result.fechaNacimiento.toString(),
            ["G", "d"]
          );
          this.DatosEmpleado = res.result;
          if (this.DatosEmpleado) {

            this.DatosEmpleado.email = this.DatosEmpleado.email
              ? this.DatosEmpleado.email.trim()
              : null;
            this.FormRecord.patchValue(this.DatosEmpleado);
            this.isLoading = false;
            this.nombreCompleto =
              this.FormRecord.value.primerNombre +
              " " +
              (this.FormRecord.value.segundoNombre
                ? this.FormRecord.value.segundoNombre
                : "") +
              " " +
              this.FormRecord.value.primerApellido +
              " " +
              (this.FormRecord.value.segundoApellido
                ? this.FormRecord.value.segundoApellido
                : "");
          }
        } else if (res.isError) {
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Cargar empleado";
          dialogSettings.description = res.errorMessage;
          dialogSettings.actions = [{ text: "Cerrar" }];
          this.sPlusFormsDialogsService.Show(dialogSettings);
          this.sPlusFormsDialogsService
            .Show(dialogSettings)
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe(() => {
              this.router.navigate(["developer/formularios"]);
            });
        } else {
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Cargar empleado";
          dialogSettings.description = res.messages.join("\n");
          dialogSettings.actions = [{ text: "Cerrar" }];
          this.sPlusFormsDialogsService
            .Show(dialogSettings)
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe(() => {
              this.router.navigate(["developer/formularios"]);
            });
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar empleado";
        dialogSettings.description =
          "Hubo un error al intentar cargar el empleado por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.sPlusFormsDialogsService.Show(dialogSettings);
      },
    });
  }


  After() {
    this.FormRecord.enable();

    if (this.id) {
      this.LoadRecord();
    } else {
      this.SetDefaultValues();
    }
    this.isLoading = false;

  }

  ngAfterViewInit() {

  }

  campoChange(event) {
    this.treeReload()
  }

  windowOpenedCampo = false
  ApiChange(evt) {
    this.JsonData.campos = evt.data
    this.windowOpenedCampo = false

    this.treeReload()
  }






  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.DatosEmpleado);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }





}


