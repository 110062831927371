<splus-forms-fields [processType]="processType">
    <splus-forms-group [title]="'Infraestructura API'" #grupoAPI>


        <splus-field *ngVar="f.ambiente as ctrl" [splusFormControl]="ctrl">
            <kendo-dropdownlist #selectAmbiente splusInput [splusGroup]="grupoAPI" [formControl]="ctrl"
                [data]="obtenerAmbienteResult" [textField]="'ambiente'" [valueField]="'idAmbiente'"
                [valuePrimitive]="true" (valueChange)="ObtenerGrupos($event)">
            </kendo-dropdownlist>

        </splus-field>



        <splus-field *ngVar="f.modulo as ctrl" [splusFormControl]="ctrl">
            <kendo-dropdownlist splusInput [splusGroup]="grupoAPI" [formControl]="ctrl" [data]="obtenerModulosResult"
                [textField]="'modulo'" [valueField]="'idModulo'" [valuePrimitive]="true"
                (valueChange)="ObtenerGrupos($event)">
            </kendo-dropdownlist>
        </splus-field>

        <splus-field *ngVar="f.grupo as ctrl" [splusFormControl]="ctrl">
            <kendo-dropdownlist splusInput [splusGroup]="grupoAPI" [formControl]="ctrl" [data]="obtenerGruposResult"
                [textField]="'grupo'" [valueField]="'idGrupo'" [valuePrimitive]="true"
                (valueChange)="ObtenerEndPoint($event)">


            </kendo-dropdownlist>
        </splus-field>



    </splus-forms-group>

    <div class="pl-2 w-full">
        <splus-field *ngVar="f.endPoint as ctrl" [splusFormControl]="ctrl">
            <div class="flex">
                <div class="w-full"> <kendo-dropdownlist #select [clearButton]="select.isOpen" splusInput
                        [splusGroup]="grupoAPI" [formControl]="ctrl" [data]="obtenerEndPointResult"
                        [textField]="'titulo'" [valueField]="'idEndPoint'" [allowCustom]="false" [valuePrimitive]="true"
                        (valueChange)="ObtenerModelo($event)">

                        <ng-template kendoComboBoxItemTemplate let-dataItem>
                            <span [ngClass]="{
                  'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300': dataItem.tipo == 'post',
                  'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300': dataItem.tipo == 'get',
                  'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300': dataItem.tipo == 'put',
                  'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300': dataItem.tipo == 'delete'
              }" class="text-xs font-medium me-2 px-2.5 py-0.5 rounded">
                                {{ dataItem.tipo }}
                            </span>


                            <span
                                class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                                {{ dataItem.endPoint }}
                            </span>


                            {{ dataItem.titulo }}
                        </ng-template>


                    </kendo-dropdownlist>
                </div>

            </div>
        </splus-field>

        <div class="bg-slate-500 mt-2 text-white p-2" *ngIf="modeloEndPoint?.modelo">
             <div>Modelo: {{modeloEndPoint?.modelo}} </div>
         </div>

        <div class="bg-slate-500 mt-2 text-white p-2" *ngIf="properties.length > 0 ">
            <div>Resultado: {{properties | json}} </div>
        </div>

        <div class="mt-10 flex">

            <div>
                <button type="button" themeColor="primary" kendoButton [disabled]="!f.endPoint.value"
                    (click)="full ? AgregarModeloEnTablaFull($event) : AgregarModeloEnTabla($event)" class=" text-primary  border cursor-pointer">
                    <i class="fa-solid fa-check"></i> Seleccionar modelo
                    {{modeloEndPoint?.modelo.split('.').pop()}}
                </button>
            </div>




        </div>
    </div>
</splus-forms-fields>