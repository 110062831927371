import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component"; 
import { FormularioDevelopSettings } from "./config/formularios.develop.settings";
import { FormulariosDevelopComponent } from "./components/formularios-develop.component";
 
const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Developer" },
  },
  { path: "", component: FormulariosDevelopComponent },
  { path: ":id", component: FormulariosDevelopComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FormularioDevelopRoutingModule {
 
  //Routing principal
  public static RoutingFormularioDevelop = {
    path: "developer/formularios",
    loadChildren: () =>
      import("./formularios-develop.module").then(
        (m) => m.FormulariosDevelopModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Developer`,
      icon: "fa-duotone fa-table-pivot",
      setting: FormularioDevelopSettings,
    },
  };
}
