import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpClient, HttpParams, HttpBackend } from "@angular/common/http";

import { RequestResult } from "src/app/models/General/RequestModel";
import { ControllerApiList } from "@app/models/SaludPlus/Controller/ControllerList";
import { SaludPlusApiResponse } from "@src/app/models/base/SaludplusApiResponse";
import { catchError, lastValueFrom, map, Observable, retry } from "rxjs";
import { RequestResultServiceService } from "@src/app/services/Generals/request-result-service.service";
import { Empleados } from "@src/app/models/Nomina/Empleados.model";

@Injectable({
  providedIn: "root",
})
export class FormulariosDevelopService {
  static instance: FormulariosDevelopService;
  error: HttpErrorResponse;
  private httpClient: HttpClient
  constructor(
    private http: HttpClient,
    handler: HttpBackend,
    private resolveRequestResultService: RequestResultServiceService,) {
    FormulariosDevelopService.instance = this;
    this.httpClient = new HttpClient(handler);
  }

  /**
   * Guardar registro
   * @param Datos datos a guardar
   */
  Guardar<T>(Datos: Empleados) {
    return this.http.post<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Empleados}`,
      Datos
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
  }
  /**
   * Actualizar  
   * @param Datos datos a actualizar
   * @param id id a actualizar
   */
  actualizarRegistro<T>(Datos: Empleados, id: number) {
    Datos.id = id;
    const record = this.http.put<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Empleados}`,
      Datos
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    return record;
  }
  /**
   * Eliminar registro
   * @param id id del registro a eliminar
   */
  async EliminarRegistro(id: number): Promise<RequestResult> {
    const record = await this.http
      .delete<any>(`${ControllerApiList.Nomina.Empleados}/${id}`)
    const dataResponse = await lastValueFrom(record);
    return dataResponse;
  }
  /**
   * Buscar empleados por su id
   * @param id Identificador del registro
   * @returns 
   */
  BuscarEmpleado<T>(id: number): Observable<SaludPlusApiResponse<T>> {
    let params = new HttpParams().set("idEmpleado", id);
    return this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Empleados}`,
      {
        params: params,
      }
    );
  }
  /**
   * Eliminar registro por su id
   * @param id Identificador del registro
   * @returns 
   */
  Delete<T>(id: number): Observable<SaludPlusApiResponse<T>> {
    let params = new HttpParams().set("idEmpleado", id);
    return this.http.delete<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Empleados}`,
      {
        params: params,
      }
    );
  }


  ObtenerSwaggerPorModulos(ambiente: string, modulo: string): Observable<any> {
    return this.http.get<any>(`${ambiente}/swagger/${modulo}/swagger.json`)
  }

  SetValueTemplate(template: string, tag: string ,value) {
    const regex = new RegExp(`${tag}`, 'g');   
    return  template.replace(regex, value); 
  }


  obtenerTemplate(type: TemplateType): Observable<string> {
    let url: string;
    switch (type) {
      case TemplateType.HTML:
        url = './assets/developer/template-formulario/components/template.component.html.template';
        break;
      case TemplateType.TS:
        url = './assets/developer/template-formulario/components/template.component.ts.template';
        break;
      case TemplateType.SCSS:
        url = './assets/developer/template-formulario/components/template.component.scss.template';
        break;
      case TemplateType.SETTING:
        url = './assets/developer/template-formulario/config/template.settings.ts.template';
        break;
      case TemplateType.SEARCH:
        url = './assets/developer/template-formulario/config/template.search.ts.template';
        break;
      case TemplateType.SERVICE:
        url = './assets/developer/template-formulario/service/template.service.ts.template';
        break;
      case TemplateType.COMPONENTE_MODULE:
        url = './assets/developer/template-formulario/template.module.ts.template';
        break;
      case TemplateType.COMPONENTE_ROUTING:
        url = './assets/developer/template-formulario/template.routing.ts.template';
        break;
      default:
        throw new Error('Tipo de template no válido');
    }

    return this.httpClient.get(url, { responseType: 'text' });
  }
}

export enum TemplateType {
  HTML = 'html',
  TS = 'ts',
  SCSS = 'scss',
  SETTING = 'setting',
  SEARCH = 'search',
  SERVICE = 'servicce',
  COMPONENTE_MODULE = 'componente-module',
  COMPONENTE_ROUTING = 'componente-routing',
}
