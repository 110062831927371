<splus-forms id="campoPage" [submit]="submitted" [messageProcess]="messageProcess" [showMessageProcess]="true"
    [FormRecord]="FormRecord">



    <form #myForm="ngForm" [formGroup]="FormRecord" (ngSubmit)="onSubmit(myForm)" autocomplete="off"
        [ngClass]="{'formGroup-read': processType == ProcessType.view}">

        <splus-forms-header [isLoading]="isLoading">
            <splus-forms-main-title [title]="'Campo (' + dataCampo?.label + ')' " [icon]="icon" [hideFavorite]="true">
            </splus-forms-main-title>

            <splus-forms-toolbar [options]="[]">
                <splus-forms-toolbar-buttons [moreOptions]="[]" [processType]="processType">

                    <button type="Submit" [disabled]="isLoading"
                        *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton
                        rounded="small" themeColor="primary">
                        <i class="fa-solid fa-floppy-disk"></i> Guardar
                    </button>


                    <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view" kendoButton
                        rounded="small" themeColor="primary" (click)="goEdit()">
                        <i class="fa-solid fa-pencil"></i> Editar
                    </button>

                    <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="cancelEdit()">
                        Cancelar
                    </button>

                    <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small"
                        themeColor="base" (click)="clearForm()">
                        Limpiar
                    </button>


                </splus-forms-toolbar-buttons>
            </splus-forms-toolbar>


        </splus-forms-header>

        <splus-forms-fields [processType]="processType">
            <div class="flex">
                <div class="flex-auto">
                    <splus-forms-group [title]="'Información Básica'" #grupoBasico>

                        <splus-field *ngVar="f.label as ctrl" [splusFormControl]="ctrl">
                            <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
                                [formControl]="ctrl" (valueChange)="labelChange($event)">

                            </kendo-textbox>
                        </splus-field>


                        <splus-field *ngVar="f.nameControl as ctrl" [splusFormControl]="ctrl">
                            <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
                                [formControl]="ctrl"> </kendo-textbox>
                        </splus-field>



                    </splus-forms-group>

                    <splus-forms-group title="Validación" #grupoValidaciones>
                        <splus-field *ngVar="f.required as ctrl" [splusFormControl]="ctrl" [viewModeDisable]="true"
                            labelCustom="">


                            <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
                                [formControl]="ctrl" [splusGroup]="grupoValidaciones" [id]="ctrl.nameControl" />

                            <ng-template splusFieldViewModeTemplate>
                                <splus-checkbox-info [checked]="ctrlCheck?.checked">
                                </splus-checkbox-info>
                            </ng-template>

                            <label [for]="processType != ProcessType.view ? ctrl.nameControl : ''" class="select-none"> {{ctrl?.label}}</label>

                        </splus-field>

                        <splus-field *ngVar="f.lookup as ctrl" [splusFormControl]="ctrl" [viewModeDisable]="true"
                            labelCustom="">

                            <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
                                [formControl]="ctrl" [splusGroup]="grupoValidaciones" [id]="ctrl.nameControl" (valueChange)="ResetPropertiesType($event == true ? ['lookupData'] : [] , [])" />


                            <ng-template splusFieldViewModeTemplate>
                                <splus-checkbox-info [checked]="ctrlCheck?.checked" >
                                </splus-checkbox-info>
                            </ng-template>

                            <label [for]="processType != ProcessType.view ?  ctrl.nameControl : ''" class="select-none"> {{ctrl?.label}}</label>

                        </splus-field>

                        <ng-container *ngIf="f.lookup.value === true">
                            <splus-field  *ngVar="f.lookupData as ctrl" [splusFormControl]="ctrl"  [viewModeDisable]="true">

                                <kendo-multiselect [checkboxes]="true" [autoClose]="false" [data]="JsonData.campos"
                                    [formControl]="ctrl" [textField]="ctrl?.dataTextField"
                                    [valueField]="ctrl?.dataValueField" 
                                    ></kendo-multiselect>



                            </splus-field>
                        </ng-container>
                    </splus-forms-group>

                    <splus-forms-group [title]="'Formato'" #grupoFormato>

                        <splus-field *ngVar="f.formato as ctrl" [splusFormControl]="ctrl">
                            <kendo-dropdownlist #selectTipo splusInput [splusGroup]="grupoFormato" [formControl]="ctrl"
                                [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                                [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">

                                
                            </kendo-dropdownlist>

                            <ng-template splusFieldViewModeTemplate>
                                {{selectTipo["text"]}}
                            </ng-template>
                        </splus-field>



                        <splus-field *ngVar="f.tipo as ctrl" [splusFormControl]="ctrl">
                            <kendo-dropdownlist #selectTipo splusInput [splusGroup]="grupoFormato" [formControl]="ctrl"
                                [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                                [valueField]="ctrl?.dataValueField" [valuePrimitive]="true"
                                (valueChange)="ResetPropertiesType(['minDate', 'maxDate'],'')">
                            </kendo-dropdownlist>

                            <ng-template splusFieldViewModeTemplate>
                                {{selectTipo["text"]}}
                            </ng-template>
                        </splus-field>



                    </splus-forms-group>
                </div>
                <div
                    class="flex-none w-80 mb-4 ml-4  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <div class="max-w-sm p-6">
                        <span>
                            <div class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Vista
                                Previa</div>
                        </span>
                        <div>

                            <ng-container [ngSwitch]="f.tipo.value">

                                <ng-container *ngSwitchCase="'Input'">

                                    <ng-container [ngSwitch]="f.formato.value">

                                        <!--Solo string -->
                                        <ng-container *ngSwitchCase="'string'">
                                            <splus-field *ngVar="formRecordPreview as ctrl" [splusFormControl]="ctrl">
                                                <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text"
                                                    [id]="ctrl.nameControl" [formControl]="ctrl">


                                                    <ng-template kendoTextBoxPrefixTemplate *ngIf="f.lookup.value">
                                                        <div class="m-1">
                                                            <i [ngClass]="JsonData.titulo.icono"
                                                                class="text-primary"></i>
                                                        </div>
                                                        <kendo-textbox-separator></kendo-textbox-separator>
                                                    </ng-template>

                                                    <!-- <ng-template kendoTextBoxSuffixTemplate>
                                                        <div *ngIf="searchingDocument" class="k-i-loading w-5">&nbsp;</div>
                                                    </ng-template>-->



                                                </kendo-textbox>
                                            </splus-field>
                                        </ng-container>

                                        <!--Solo numeros -->
                                        <ng-container *ngSwitchCase="'integer|int32'">
                                            <splus-field *ngVar="formRecordPreview as ctrl" [splusFormControl]="ctrl">
                                                <kendo-numerictextbox splusInput [splusGroup]="grupoBasico"
                                                    [id]="ctrl.nameControl" [spinners]="false" format="#"
                                                    [formControl]="ctrl"></kendo-numerictextbox>


                                                <ng-template kendoTextBoxPrefixTemplate *ngIf="f.lookup.value">
                                                    <div class="m-1">
                                                        <i [ngClass]="JsonData.titulo.icono" class="text-primary"></i>
                                                    </div>
                                                    <kendo-textbox-separator></kendo-textbox-separator>
                                                </ng-template>

                                            </splus-field>
                                        </ng-container>

                                        <!--Solo numeros -->
                                        <ng-container *ngSwitchCase="'integer|int64'">
                                            <splus-field *ngVar="formRecordPreview as ctrl" [splusFormControl]="ctrl">
                                                <kendo-numerictextbox splusInput [splusGroup]="grupoBasico"
                                                    [id]="ctrl.nameControl" [spinners]="false" format="#"
                                                    [formControl]="ctrl">


                                                    <ng-template kendoTextBoxPrefixTemplate *ngIf="f.lookup.value">
                                                        <div class="m-1">
                                                            <i [ngClass]="JsonData.titulo.icono"
                                                                class="text-primary"></i>
                                                        </div>
                                                        <kendo-textbox-separator></kendo-textbox-separator>
                                                    </ng-template>


                                                </kendo-numerictextbox>
                                            </splus-field>
                                        </ng-container>

                                    </ng-container>
                                </ng-container>

                                <ng-container *ngSwitchCase="'List'">


                                    <splus-field *ngVar="formRecordPreview as ctrl" [splusFormControl]="ctrl">
                                        <div class="flex">
                                            <kendo-dropdownlist #select splusInput [splusGroup]="grupoBasico"
                                                [formControl]="ctrl"
                                                [data]="GetDataApi(formRecordPreview.nameControl) | async"
                                                [textField]="GetProperty(formRecordPreview.nameControl,'dataTextField')"
                                                [valueField]="GetProperty(formRecordPreview.nameControl,'dataValueField')"
                                                [valuePrimitive]="true">
                                            </kendo-dropdownlist>


                                        </div>
                                        <ng-template splusFieldViewModeTemplate>
                                            {{select["text"]}}
                                        </ng-template>


                                    </splus-field>


                                </ng-container>

                                <!--Calendario -->
                                <ng-container *ngSwitchCase="'Date'">
                                    <splus-field *ngVar="formRecordPreview as ctrl" [splusFormControl]="ctrl" #fecha>
                                        <kendo-datepicker #calendario splusInput [navigation]="true" format="dd/MM/yyyy"
                                            [max]="ctrl?.maxDate ? ctrl.maxDate : ''"
                                            [min]="ctrl?.minDate ? ctrl.minDate : ''" [formControl]="ctrl"
                                            [splusGroup]="grupoBasico" [splusField]="fecha" [value]="ctrl.value">

                                            <ng-template kendoCalendarNavigationItemTemplate let-title>
                                                {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
                                            </ng-template>

                                        </kendo-datepicker>

                                        <ng-template splusFieldViewModeTemplate>
                                            {{calendario.value | SPlusDate}}
                                        </ng-template>

                                        <div class="hit">
                                            {{!calendario.value ? '' : calendario.value | InfoDateFormat}}
                                        </div>


                                    </splus-field>
                                </ng-container>

                                <!--checkbox -->
                                <ng-container *ngSwitchCase="'CheckBox'">
                                    <splus-field *ngVar="formRecordPreview as ctrl" [splusFormControl]="ctrl"
                                        [viewModeDisable]="true">
                                        <div>
                                            <input #ck type="checkbox" [readonly]="true" rounded="small" splusInput
                                                kendoCheckBox [formControl]="ctrl" [splusGroup]="grupoBasico"
                                                [id]="ctrl.nameControl" />
                                        </div>

                                        <ng-template splusFieldViewModeTemplate>
                                            <splus-checkbox-info [checked]="ck?.checked">
                                            </splus-checkbox-info>
                                        </ng-template>

                                    </splus-field>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                    no se ha definido este tipo de control {{f.tipo}}

                                </ng-container>
                            </ng-container>

                        </div>
                    </div>

                </div>
            </div>

            <splus-forms-group title="Fuente de datos" #grupoDatos>


                <splus-field *ngVar="f.fuenteDatos as ctrl" [splusFormControl]="ctrl">
                    <div class="flex items-start">
                        <kendo-dropdownlist #select splusInput [splusGroup]="grupoDatos" [formControl]="ctrl"
                            [disabled]="f.tipo.value != 'List'" [data]="ctrl?.funtionData | async"
                            [textField]="ctrl?.dataTextField" [valueField]="ctrl?.dataValueField"
                            [valuePrimitive]="true">

                        </kendo-dropdownlist>

                        <i (click)=" f.fuenteDatos.value == 'Api' && windowOpened = true"
                            [ngClass]="{'text-gray-400' : f.fuenteDatos.value != 'Api'}"
                            class="popup-icon fa-duotone fa-arrow-up-right-from-square"></i>
                    </div>
                    <div class="text-blue-600 text-sm">

                        {{GetDataCampo?.properties?.endPoint}}</div>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>


                <splus-field *ngVar="f.dataTextField as ctrl" [splusFormControl]="ctrl">
                    <kendo-dropdownlist [disabled]="f.tipo.value != 'List'" #select splusInput [splusGroup]="grupoDatos"
                        [formControl]="ctrl" [data]="dataModel ? dataModel.data : []" [textField]="ctrl?.dataTextField"
                        [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">
                    </kendo-dropdownlist>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>

                <splus-field *ngVar="f.dataValueField as ctrl" [splusFormControl]="ctrl">
                    <kendo-dropdownlist [disabled]="f.tipo.value != 'List'" #select splusInput [splusGroup]="grupoDatos"
                        [formControl]="ctrl" [data]="dataModel ? dataModel.data : []" [textField]="ctrl?.dataTextField"
                        [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">
                    </kendo-dropdownlist>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>
            </splus-forms-group>


            <splus-forms-group title="Personalización" #grupoDato>

                <ng-container [ngSwitch]="f.tipo.value">

                    <ng-container *ngSwitchCase="'List'">
                        <splus-field *ngVar="f.defaultValue as ctrl" [splusFormControl]="ctrl" #FieldDataValue>

                            <kendo-combobox #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
                                [data]="GetDataApi(f.nameControl.value) | async" [textField]="f?.dataTextField?.value"
                                [valueField]="f?.dataValueField?.value" [valuePrimitive]="true">
                            </kendo-combobox>

                            <ng-template splusFieldViewModeTemplate>
                                {{select["text"]}}
                            </ng-template>

                        </splus-field>

                    </ng-container>

                    <ng-container *ngSwitchCase="'Input'">

                        <splus-field *ngVar="f.defaultValue as ctrl" [splusFormControl]="ctrl" #FieldDataValue>
                            <kendo-textbox splusInput [splusGroup]="grupoDatos" type="text" [id]="ctrl.nameControl"
                                [formControl]="ctrl"> </kendo-textbox>
                        </splus-field>

                    </ng-container>



                    <ng-container *ngSwitchCase="'CheckBox'">
                        <splus-field *ngVar="f.defaultValue as ctrl" [splusFormControl]="ctrl" #FieldDataValue>
                            <div>
                                <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput
                                    kendoCheckBox [formControl]="ctrl" [splusGroup]="grupoDatos"
                                    [id]="ctrl.nameControl" />
                            </div>

                            <ng-template splusFieldViewModeTemplate>
                                <splus-checkbox-info [checked]="ctrlCheck?.checked">
                                </splus-checkbox-info>
                            </ng-template>
                        </splus-field>

                    </ng-container>



                    <ng-container *ngSwitchCase="'Date'">
                        <splus-field *ngVar="f.defaultValue as ctrl" [splusFormControl]="ctrl" #FieldDataValue>
                            <kendo-combobox #select splusInput [splusGroup]="grupoSeparador" [formControl]="ctrl"
                                [data]="[{valueField : 'fecha_actual', textField: '🗓️ (Fecha Actual)'}]"
                                textField="textField" valueField="valueField" [valuePrimitive]="true">
                            </kendo-combobox>


                            <ng-template splusFieldViewModeTemplate>
                                {{select["text"]}}
                            </ng-template>
                        </splus-field>

                        <splus-field *ngVar="f.minDate as ctrl" [splusFormControl]="ctrl" #FieldDataValue>
                            <kendo-combobox #select splusInput [splusGroup]="grupoSeparador" [formControl]="ctrl"
                                [data]="[{valueField : 'fecha_actual', textField: '🗓️ (Fecha Actual)'}]"
                                textField="textField" valueField="valueField" [valuePrimitive]="true">
                            </kendo-combobox>


                            <ng-template splusFieldViewModeTemplate>
                                {{select["text"]}}
                            </ng-template>
                        </splus-field>


                        <splus-field *ngVar="f.maxDate as ctrl" [splusFormControl]="ctrl" #FieldDataValue>
                            <kendo-combobox #select splusInput [splusGroup]="grupoSeparador" [formControl]="ctrl"
                                [data]="[{valueField : 'fecha_actual', textField: '🗓️ (Fecha Actual)'}]"
                                textField="textField" valueField="valueField" [valuePrimitive]="true">
                            </kendo-combobox>


                            <ng-template splusFieldViewModeTemplate>
                                {{select["text"]}}
                            </ng-template>
                        </splus-field>


                    </ng-container>



                </ng-container>




            </splus-forms-group>

            <splus-forms-group [title]="'Separador'" #grupoSeparador>


                <splus-field *ngVar="f.separador as ctrl" [splusFormControl]="ctrl">
                    <kendo-dropdownlist #select splusInput [splusGroup]="grupoSeparador" [formControl]="ctrl"
                        [data]="JsonData.separadores" [textField]="ctrl?.dataTextField"
                        [valueField]="ctrl?.dataValueField" [valuePrimitive]="true">
                    </kendo-dropdownlist>

                    <ng-template splusFieldViewModeTemplate>
                        {{select["text"]}}
                    </ng-template>
                </splus-field>





            </splus-forms-group>



        </splus-forms-fields>

    </form>


</splus-forms>


<kendo-dialog class="kendo-dialog-resultado" title="Obtener modelo" *ngIf="windowOpened" maxWidth="95%" minWidth="50%"
    maxHeight="95%" minHeight="400px" (close)="windowOpened = false">
    <div>
        <app-infraestructura-api-develop (dataChange)="ApiChange($event)"
            [full]="false"></app-infraestructura-api-develop>
    </div>
</kendo-dialog>