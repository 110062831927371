import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ShareInfraestructaApiSettings } from '../../models/shared-infraestructura-api';
import { HttpErrorResponse } from '@angular/common/http';
import { ProcessType, SPlusDialogSettings, SPlusFormsDialogsService } from '@saludplus/forms';
import { FormulariosDevelopService } from '../../services/formulario.developer.service'; 
import { FormularioDevelopDto } from '../../models/FormularioDevelopDto';

@Component({
  selector: 'app-infraestructura-api-develop',
  templateUrl: './infraestructura-api-develop.component.html',
  styleUrls: ['./infraestructura-api-develop.component.css']
})
export class SharedInfraestructuraApiComponent implements OnInit {

  public processType: ProcessType = ProcessType.create;
  public ProcessType = ProcessType;
  
  obtenerModulosResult: any[] = [
    { 'modulo': 'SaludPlus', 'idModulo': 'SaludPlus' },
    { 'modulo': 'Administracion', 'idModulo': 'Administracion' },
    { 'modulo': 'Facturacion', 'idModulo': 'Facturacion' },
    { 'modulo': 'Citas', 'idModulo': 'Citas' },
    { 'modulo': 'HistoriasClinicas', 'idModulo': 'HistoriasClinicas' },
    { 'modulo': 'Triage', 'idModulo': 'Triage' },
    { 'modulo': 'Asistencial', 'idModulo': 'Asistencial' },
    { 'modulo': 'Inventario', 'idModulo': 'Inventario' },
    { 'modulo': 'Auditoria', 'idModulo': 'Auditoria' },
    { 'modulo': 'ImagenesDiagnosticas', 'idModulo': 'ImagenesDiagnosticas' },
    { 'modulo': 'Laboratorio', 'idModulo': 'Laboratorio' },
    { 'modulo': 'Calidad', 'idModulo': 'Calidad' },
    { 'modulo': 'Contabilidad', 'idModulo': 'Contabilidad' },
    { 'modulo': 'Presupuesto', 'idModulo': 'Presupuesto' },
    { 'modulo': 'Nomina', 'idModulo': 'Nomina' },
  ];
  obtenerAmbienteResult: any[] = [
    { 'ambiente': 'Pruebas', 'idAmbiente': 'https://api-pruebas.saludplus.co' },
    { 'ambiente': 'Pre Producción', 'idAmbiente': 'https://api-pre.saludplus.co' },
    { 'ambiente': 'Producción', 'idAmbiente': 'https://api.saludplus.co' }
  ];

  obtenerGruposResult: any[] = []
  obtenerEndPointResultAll: any[] = []
  obtenerEndPointResult: any[] = []
  modeloEndPoint: any
  swaggerJson: any
  FormRecord: UntypedFormGroup;

  /**
   * returno campo completo con datos adicional
   */
  @Input() full = true;

  @Output() dataChange = new EventEmitter<any>();

  private sPlusFormsDialogsService = inject(SPlusFormsDialogsService); 
  constructor(
    private shareInfraestructaApiSettings : ShareInfraestructaApiSettings, 
    private fomulariosDevelopeService: FormulariosDevelopService,
  ) { }

  ngOnInit() {
    this.loadFormComponents();
  }

  public get f(): any {
    return this.FormRecord.controls;
  }

    /**
   * Componentes del formulario
   */
    loadFormComponents() {
      this.FormRecord = this.shareInfraestructaApiSettings.FormComponents();
    }

  /**
     * Cargar grupos por modulos
     * @param modulo Identificador del modulo
     */
  ObtenerGrupos(modulo: string) {

    this.obtenerGruposResult = [];
    this.obtenerEndPointResultAll = [];

    if (!this.f.ambiente.value || !this.f.modulo.value) {
      return;
    } else {

      this.FormRecord.patchValue({
        grupo: "",
      });
      this.fomulariosDevelopeService
        .ObtenerSwaggerPorModulos(this.f.ambiente.value, this.f.modulo.value).subscribe({
          next: (result) => {

            this.swaggerJson = result
            let keys = Object.keys(result.paths);

            for (let key of keys) {
              if (key != '/api/auth/Login') {
                let value = this.swaggerJson.paths[key];

                let keysTiposPeticion = Object.keys(value);
                for (let keytp of keysTiposPeticion) {

                  let valuetp = value[keytp];
                  if (valuetp['tags'] && valuetp['tags'].length > 0) {

                    let tipoText = valuetp['tags'][0];
                    let titulo = valuetp['summary'];
                    let modelo = ''

                    if (valuetp['requestBody'] &&
                      valuetp['requestBody']['content'] &&
                      valuetp['requestBody']['content']['application/json'] &&
                      valuetp['requestBody']['content']['application/json']['schema'] &&
                      valuetp['requestBody']['content']['application/json']['schema']['$ref']) {

                      modelo = valuetp['requestBody']['content']['application/json']['schema']['$ref'].replace('#/components/schemas/', '');
                    }else if(valuetp['responses'] &&
                      valuetp['responses']['200'] &&
                      valuetp['responses']['200']['content'] &&
                      valuetp['responses']['200']['content']['application/json'] &&
                      valuetp['responses']['200']['content']['application/json']['schema']  &&
                      valuetp['responses']['200']['content']['application/json']['schema']['$ref']  
                    ){ 
                      let modeloFull = valuetp['responses']['200']['content']['application/json']['schema']['$ref']
                      const regex = /System\.Collections\.Generic\.List`1\[\[(?<typeName>[^\[\],]+)/;
                      const match = modeloFull.match(regex);
                      if (match && match.groups) {
 
                        modelo = match.groups["typeName"];
                           
                      }

                       
                    }


                    if (!this.obtenerGruposResult.some(item => item.grupo === tipoText)) {
                      this.obtenerGruposResult.push({ 'grupo': tipoText, 'idGrupo': tipoText });
                    }

                    this.obtenerEndPointResultAll.push({ endPoint: key, idEndPoint: key, tipo: keytp, grupo: tipoText, titulo: titulo, modelo: modelo })
                  }
                }
              } 
            }
          },
          error: (err) => {
            if (err instanceof HttpErrorResponse && err.status == 401) return;
            console.warn("Error critico", err);
            let dialogSettings = {} as SPlusDialogSettings;
            dialogSettings.title = "Error cargar los grupos";
            dialogSettings.description =
              "Hubo un error al intentar cargar el listado de los grupos";
            dialogSettings.actions = [{ text: "Cerrar" }];
            this.sPlusFormsDialogsService.Show(dialogSettings);
          },
        });



    }
  }

  ObtenerModelo(modelo) { 
    this.modeloEndPoint = this.obtenerEndPointResult.find(s => s.endPoint == modelo);

    var modelos = this.swaggerJson['components']['schemas'][this.modeloEndPoint['modelo']];
    let keys = Object.keys(modelos['properties']);
    this.properties = keys

  }

  /**
     * Cargar grupos por modulos
     * @param modulo Identificador del modulo
     */


  properties = []
  AgregarModeloEnTabla(event: any) {
     
    var modelos = this.swaggerJson['components']['schemas'][this.modeloEndPoint['modelo']];
    let keys = Object.keys(modelos['properties']);
    this.properties = keys

    let  propertiesMap = keys.map(key => ({ id: key, name: key }));

    let properties =  this.FormRecord.value;
    properties['data'] = propertiesMap
      
    this.dataChange.emit(properties)
  }

  AgregarModeloEnTablaFull(event: any) {

    let campos = []
    var modelos = this.swaggerJson['components']['schemas'][this.modeloEndPoint['modelo']];

    let keys = Object.keys(modelos['properties']);

    for (let key of keys) {
      let value = modelos['properties'][key];

      let format = value['format'];
      let nullable = value['nullable'];
      let type = value['type'];

      var single = new FormularioDevelopDto();
      single.label = this.ConvertirCamelCaseATextoAmigable(key);
      single.nameControl = key;
      single.required = nullable ? true : false;
      single.separador = 'separadorInformacionBasica'

      switch (type) {
        case 'boolean':
          single.tipo = 'CheckBox'
          break;

        default:
          single.tipo = 'Input'
          break;
      }

      if (key.includes('fecha')) {
        single.tipo = 'Date'
      }

      if (format) {
        single.formato = `${type}|${format}`;
      } else {
        single.formato = `${type}`;
      }

        campos.push(single);

    }

    let properties =  this.FormRecord.value;
    properties['data'] = campos
    this.dataChange.emit(properties)

  }


  ConvertirCamelCaseATextoAmigable(cadena: string): string {
    const regex = /([a-z])([A-Z])/g;
    if (cadena.startsWith('id') && cadena.length > 2) {
      cadena = cadena.substring(2);
    }
    return cadena.replace(regex, '$1 $2').replace(/^./, (str) => str.toUpperCase());
  }

  ObtenerEndPoint(grupo) {

    if (!grupo) {
      this.obtenerEndPointResult = [];
    } else {
      this.obtenerEndPointResult = []

      this.FormRecord.patchValue({
        endPointt: "",
      });

      this.obtenerEndPointResult = this.obtenerEndPointResultAll.filter(s => s.grupo == grupo);

    }
  }
}
