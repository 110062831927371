<kendo-grid [rowReorderable]="true" [kendoGridReactiveEditing]="createFormGroup" [kendoGridBinding]="this.JsonData.separadores" [sortable]="false"
[navigable]="true" (save)="saveHandler($event)">

<ng-template kendoGridToolbarTemplate [position]="'top'">
    <button kendoGridAddCommand *ngIf="processType != ProcessType.view " rounded="small" themeColor="primary"
        id="bt-add">Adicionar Separador</button>

        <kendo-grid-spacer></kendo-grid-spacer>
 
      
  
</ng-template>

<kendo-grid-rowreorder-column [width]="40"></kendo-grid-rowreorder-column>

<kendo-grid-column *ngVar="formGroupSettingTabCampos?.controls['nombre'] as ctrl" [field]="ctrl.nameControl"
    [title]="ctrl?.label" editor="text">
</kendo-grid-column> 
 
<kendo-grid-column *ngVar="formGroupSettingTabCampos?.controls['tag'] as ctrl"  [editable]="false" [field]="ctrl.nameControl"
    [title]="ctrl?.label"  >
</kendo-grid-column>


<kendo-grid-command-column title="Opciones" [width]="220">
    <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand>
           <div *ngIf="isNew"><i class="fa-regular fa-floppy-disk  text-primary"></i> Guardar</div> 
           <div *ngIf="!isNew"><i class="fa-regular fa-pencil  text-primary"></i> Editar</div> 
        </button>
        <button kendoGridRemoveCommand>
            <i class="fa-regular fa-trash text-red-800"></i> Eliminar
        </button>

        <button kendoGridSaveCommand [disabled]="formGroupSettingTabCampos.invalid">
            <div *ngIf="isNew"><i class="fa-light fa-floppy-disk text-primary"></i> Guardar</div>
            <div *ngIf="!isNew"><i class="fa-duotone fa-pencil"></i> Guardar</div>
        </button>
        <button kendoGridCancelCommand>
            <div *ngIf="isNew"><i class="fa-regular fa-trash-undo text-primary"></i> Descartar</div>
            <div *ngIf="!isNew">Cancelar</div>
        </button>
    </ng-template>


</kendo-grid-command-column>
</kendo-grid> 

 