import { signal } from "@angular/core"
const json =  
  {
    "titulo": {
      "icono": null,
      "titulo": "Configuracion Conceptos"
    },
    "tipoformulario": 1,
    "modulo": "nomina",
    "separadores": [
      {
        "nombre": "Información Básica",
        "tag": "separadorInformacionBasica"
      },
      {
        "nombre": "Información Terceros",
        "tag": "InformacionTerceros"
      }
    ],
    "campos": [
      {
        "label": "Nombre",
        "nameControl": "descripcion",
        "tipo": "Input",
        "defaultValue": null,
        "minDate": "string",
        "maxDate": "string",
        "required": true,
        "lookup": null,
        "lookupData": null,
        "formato": "string",
        "separador": "separadorInformacionBasica",
        "fuenteDatos": null,
        "dataTextField": null,
        "dataValueField": null
      },
      {
        "label": "Llave",
        "nameControl": "llave",
        "required": true,
        "separador": "separadorInformacionBasica",
        "tipo": "Input",
        "formato": "string"
      },
      {
        "label": "Tipo Concepto",
        "nameControl": "idTipoConcepto",
        "tipo": "List",
        "defaultValue": null,
        "minDate": "",
        "maxDate": "",
        "required": true,
        "lookup": null,
        "lookupData": null,
        "formato": "integer|int32",
        "separador": "separadorInformacionBasica",
        "fuenteDatos": "Api",
        "dataTextField": "nombre",
        "dataValueField": "id",
        "properties": {
          "ambiente": "https://api-pruebas.saludplus.co",
          "modulo": "Nomina",
          "grupo": "ConfiguracionConceptosNomina",
          "endPoint": "/api/configuracionConceptosNomina/TiposConceptos",
          "data": [
            {
              "id": "id",
              "name": "id"
            },
            {
              "id": "nombre",
              "name": "nombre"
            },
            {
              "id": "abreviatura",
              "name": "abreviatura"
            },
            {
              "id": "order",
              "name": "order"
            }
          ]
        }
      },
      {
        "label": "Tipo Novedad",
        "nameControl": "idTipoNovedad",
        "tipo": "List",
        "defaultValue": null,
        "minDate": "",
        "maxDate": "",
        "required": true,
        "lookup": null,
        "lookupData": null,
        "formato": "integer|int32",
        "separador": "separadorInformacionBasica",
        "fuenteDatos": "Api",
        "dataTextField": "nombre",
        "dataValueField": "id",
        "properties": {
          "ambiente": "https://api-pruebas.saludplus.co",
          "modulo": "Nomina",
          "grupo": "ConfiguracionConceptosNomina",
          "endPoint": "/api/configuracionConceptosNomina/TipoNovedades",
          "data": [
            {
              "id": "id",
              "name": "id"
            },
            {
              "id": "nombre",
              "name": "nombre"
            },
            {
              "id": "abreviatura",
              "name": "abreviatura"
            },
            {
              "id": "order",
              "name": "order"
            }
          ]
        }
      },
      {
        "label": "Constante",
        "nameControl": "constante",
        "tipo": "Input",
        "defaultValue": null,
        "minDate": "string",
        "maxDate": "string",
        "required": false,
        "lookup": null,
        "lookupData": null,
        "formato": "number|double",
        "separador": "separadorInformacionBasica",
        "fuenteDatos": null,
        "dataTextField": null,
        "dataValueField": null
      },
      {
        "label": "Formula",
        "nameControl": "formula",
        "tipo": "Input",
        "defaultValue": null,
        "minDate": "string",
        "maxDate": "string",
        "required": false,
        "lookup": null,
        "lookupData": null,
        "formato": "string",
        "separador": "separadorInformacionBasica",
        "fuenteDatos": null,
        "dataTextField": null,
        "dataValueField": null
      },
      {
        "label": "Query",
        "nameControl": "idQuery",
        "tipo": "Input",
        "defaultValue": null,
        "minDate": "string",
        "maxDate": "string",
        "required": false,
        "lookup": null,
        "lookupData": null,
        "formato": "integer|int32",
        "separador": "separadorInformacionBasica",
        "fuenteDatos": null,
        "dataTextField": null,
        "dataValueField": null
      },
      {
        "label": "Opcional",
        "nameControl": "opcional",
        "required": false,
        "separador": "separadorInformacionBasica",
        "tipo": "CheckBox",
        "formato": "boolean"
      },
      {
        "label": "Aplica Tercero",
        "nameControl": "aplicaTercero",
        "tipo": "CheckBox",
        "defaultValue": null,
        "minDate": "string",
        "maxDate": "string",
        "required": false,
        "lookup": null,
        "lookupData": null,
        "formato": "boolean",
        "separador": "InformacionTerceros",
        "fuenteDatos": null,
        "dataTextField": null,
        "dataValueField": null
      },
      {
        "label": "Tercero Default",
        "nameControl": "idTerceroDefault",
        "tipo": "Input",
        "defaultValue": null,
        "minDate": "string",
        "maxDate": "string",
        "required": false,
        "lookup": null,
        "lookupData": null,
        "formato": "integer|int32",
        "separador": "InformacionTerceros",
        "fuenteDatos": null,
        "dataTextField": null,
        "dataValueField": null
      }
    ]
  }
 /*
const json = 
{
  "titulo": {
    "icono": "fa-regular fa-user-tie-hair",
    "titulo": "Empleados"
  },
  "tipoformulario": 1,
  "modulo" : "nomina",
  "separadores": [
    {
      "nombre": "Información Básica",
      "tag": "separadorInformacionBasica"
    },
    {
      "nombre": "Información Dirección",
      "tag": "separadorInformacionDireccion"
    }
  ],
  "campos": [
    {
      "label": "Numero Documento",
      "nameControl": "numeroDocumento",
      "tipo": "Input",
      "defaultValue": "",
      "minDate": "string",
      "maxDate": "string",
      "required": true,
      "lookup": true,
      "lookupData": [
        {
          "label": "Numero Documento",
          "nameControl": "numeroDocumento",
          "tipo": "Input",
          "defaultValue": null,
          "minDate": "string",
          "maxDate": "string",
          "required": true,
          "lookup": true,
          "formato": "string",
          "separador": "separadorInformacionBasica",
          "fuenteDatos": null,
          "dataTextField": null,
          "dataValueField": null
        },
        {
          "label": "Tipo Documento",
          "nameControl": "tipoDocumento",
          "tipo": "List",
          "defaultValue": null,
          "minDate": "string",
          "maxDate": "string",
          "required": true,
          "lookup": false,
          "formato": "string",
          "separador": "separadorInformacionBasica",
          "fuenteDatos": "Api",
          "dataTextField": "nombre",
          "dataValueField": "valor",
          "properties": {
            "ambiente": "https://api-pruebas.saludplus.co",
            "modulo": "SaludPlus",
            "grupo": "General",
            "endPoint": "/api/general/Documentos",
            "data": [
              {
                "id": "valor",
                "name": "valor"
              },
              {
                "id": "nombre",
                "name": "nombre"
              }
            ]
          }
        }
      ],
      "formato": "string",
      "separador": "separadorInformacionBasica",
      "fuenteDatos": null,
      "dataTextField": null,
      "dataValueField": null
    },
    {
      "label": "Tipo Documento",
      "nameControl": "tipoDocumento",
      "tipo": "List",
      "defaultValue": null,
      "minDate": "string",
      "maxDate": "string",
      "required": true,
      "lookup": false,
      "formato": "string",
      "separador": "separadorInformacionBasica",
      "fuenteDatos": "Api",
      "dataTextField": "nombre",
      "dataValueField": "valor",
      "properties": {
        "ambiente": "https://api-pruebas.saludplus.co",
        "modulo": "SaludPlus",
        "grupo": "General",
        "endPoint": "/api/general/Documentos",
        "data": [
          {
            "id": "valor",
            "name": "valor"
          },
          {
            "id": "nombre",
            "name": "nombre"
          }
        ]
      }
    },
    {
      "label": "Primer Nombre",
      "nameControl": "primerNombre",
      "required": true,
      "separador": "separadorInformacionBasica",
      "tipo": "Input",
      "formato": "string"
    },
    {
      "label": "Segundo Nombre",
      "nameControl": "segundoNombre",
      "required": true,
      "separador": "separadorInformacionBasica",
      "tipo": "Input",
      "formato": "string"
    },
    {
      "label": "Primer Apellido",
      "nameControl": "primerApellido",
      "required": true,
      "separador": "separadorInformacionBasica",
      "tipo": "Input",
      "formato": "string"
    },
    {
      "label": "Segundo Apellido",
      "nameControl": "segundoApellido",
      "required": true,
      "separador": "separadorInformacionBasica",
      "tipo": "Input",
      "formato": "string"
    },
    {
      "label": "Fecha Nacimiento",
      "nameControl": "fechaNacimiento",
      "tipo": "Date",
      "defaultValue": null,
      "maxDate": "fecha_actual",
      "required": true,
      "formato": "string",
      "separador": "separadorInformacionBasica",
      "fuenteDatos": null,
      "dataTextField": null,
      "dataValueField": null
    },
    {
      "label": "Sexo",
      "nameControl": "idSexo",
      "tipo": "List",
      "required": true,
      "formato": "integer|int32",
      "separador": "separadorInformacionBasica",
      "fuenteDatos": "Api",
      "dataTextField": "sexo",
      "dataValueField": "idSexo",
      "properties": {
        "ambiente": "https://api-pruebas.saludplus.co",
        "modulo": "SaludPlus",
        "grupo": "General",
        "endPoint": "/api/general/Sexos",
        "data": [
          {
            "id": "idSexo",
            "name": "idSexo"
          },
          {
            "id": "sexo",
            "name": "sexo"
          }
        ]
      }
    },
    {
      "label": "Estado",
      "nameControl": "estado",
      "tipo": "CheckBox",
      "defaultValue": true,
      "required": false,
      "formato": "boolean",
      "separador": "separadorInformacionBasica",
      "fuenteDatos": null,
      "dataTextField": null,
      "dataValueField": null
    },
    {
      "label": "Direccion",
      "nameControl": "direccion",
      "required": true,
      "separador": "separadorInformacionDireccion",
      "tipo": "Input",
      "formato": "string"
    },
    {
      "label": "Telefono",
      "nameControl": "telefono",
      "required": true,
      "separador": "separadorInformacionDireccion",
      "tipo": "Input",
      "formato": "integer|int64"
    },
    {
      "label": "Correo Electrónico",
      "nameControl": "email",
      "tipo": "Input",
      "defaultValue": null,
      "required": false,
      "formato": "string",
      "separador": "separadorInformacionDireccion",
      "fuenteDatos": null,
      "dataTextField": null,
      "dataValueField": null
    }
  ],  
} */
export const formularioJSON = signal<any>(json)
 




