import { AbstractControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, Inject } from "@angular/core";
import { PersistenceService } from "src/app/services/Utilities/data.persistence.service";
import { LocalStorageList } from "src/app/helpers/enums/enums.keys";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  DateFilter,
  MultipleFilter,
  MultipleFilterData,
} from "@src/app/models/Tegett/SimpleFilterData";
import { TiposDocumentos } from "@src/app/models/SaludPlus/TiposDocumentos.model";
import { Ciudades } from "@src/app/models/SaludPlus/Ciudades.model";
import Validation from "@src/app/components/Core/directives/generals/validatorError";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { lastValueFrom } from "rxjs";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods } from "@src/app/models/base/filterModel";
import { FormulariosDevelopService } from "../services/formulario.developer.service";

@Component({
  selector: "app-developerSettings",
  template: "",
})
export class FormularioDevelopSettings implements BaseSearchSetting {

  tiposDocumentos: MultipleFilter;


  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(FormulariosDevelopService) private empleadoServices: FormulariosDevelopService
  ) {

  }
  async Load() {
    /* this.tiposDocumentos = await this.ObtenerTiposDocumento();
 
     if (this.tiposDocumentos) {
       return true;
     }
     else {
       return false;
     }
 */
    return true;
  }
  /**
   * Funcion para armar la busqueda
   */
  loadFormSearch(): FormSearch {
    const empleadoSearch = new FormSearch();
    empleadoSearch.formComponents = this.FormComponents();
    empleadoSearch.configSearch = {
      title: "Developer",
      titleIcon: "fa-regular fa-user-tie-hair",
      apiSearch: ControllerApiList.Nomina.Empleados,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nuevo Form",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),

      settings: [
        { objectKey: "numeroDocumento", sortEnabled: false, visible: true },
        { objectKey: "fechaNacimiento", sortEnabled: false, visible: true },
        { objectKey: "id", sortV3: "desc", visible: false },
      ],
      fields: [
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },
        {
          renderLabel: "Empleado",
          objectKey: "nombre",
          module: "empleados",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
        },
        {
          objectKey: "tipoDocumento",
          renderLabel: "Tipo de Documento",
        },
        {
          objectKey: "numeroDocumento",
          renderLabel: "Número Identificación",
        },

        {
          objectKey: "fechaNacimiento",
          //pipe: "date",
          renderLabel: "Fecha de Nacimiento",
        },
        {
          objectKey: "estado",
          renderLabel: "Estado",
          render: (value) => `
          <div  class="t-row">
            <div class="item-state-circle t-color-${value == true ? 'activo' : value == false ? 'inactivo' : 'ninguno'}"></div>
            <div>${value == true ? 'Activo' : value == false ? 'Inactivo' : 'ninguno'}</div>
          </div>`,
        },
      ],
      filters: [
        { objectKey: "numeroDocumento", type: SearchTypeFilter.Text },
        { objectKey: "primerNombre", type: SearchTypeFilter.Text },
        { objectKey: "segundoNombre", type: SearchTypeFilter.Text },
        { objectKey: "primerApellido", type: SearchTypeFilter.Text },
        { objectKey: "segundoApellido", type: SearchTypeFilter.Text },
        {
          objectKey: "estado",
          type: SearchTypeFilter.MultipleFilter,
          data: this.ObtenerEstados(),
        }/*, 
        {
          objectKey: "tipoDocumento",
          type: SearchTypeFilter.MultipleFilter,
          data: this.tiposDocumentos,
        },*/

      ],
    };
    return empleadoSearch;
  }

  ConfigDate() {
    let filter = {} as DateFilter;
    filter.label = "Fecha Nacimiento";
    filter.name = "fechaNacimiento";
    filter.type = SearchTypeFilter.DateFilter;

    return filter;
  }


  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }
  /**
   * Lista de combos de empleado
   */
  ListaCombos() {
    const listaCombos = [
      "sexos",
      "tiposdocumentos"
    ];
    return listaCombos;
  }

  ObtenerEstados() {
    let estados: any[] = [];
    estados.push(
      { estado: "Activo", valor: true },
      { estado: "Inactivo", valor: false }
    );
    let filter = {} as MultipleFilter;
    filter.data = [];
    filter.label = "Estado";
    filter.type = SearchTypeFilter.MultipleFilter;

    estados.forEach((elemt, index) => {
      let estado = {} as MultipleFilterData;
      estado.field = index;
      estado.text = elemt.estado;
      estado.value = elemt.valor;
      estado.default = elemt.valor == true ? true : false
      if (elemt.estado == "Activo") {
        estado.htmlBefore =
          '<div class="item-state-circle t-color-activo"></div>';
      }
      if (elemt.estado == "Inactivo") {
        estado.htmlBefore =
          '<div class="item-state-circle t-color-inactivo"></div>';
      }
      filter.data.push(estado);
    });
    return filter;

  }
  /*
    async ObtenerTiposDocumento() {
  
      let filter = {} as MultipleFilter;
      filter.data = [];
      filter.label = "Tipos Documentos";
      filter.type = SearchTypeFilter.MultipleFilter;
      let b = this.empleadoServices.ObtenerTiposDocumentos<TiposDocumentos[]>();
      const dataResponse = await lastValueFrom(b);
      if (dataResponse.isSuccessful) {
        dataResponse.result.forEach((elemt, index) => {
          let estado = {} as MultipleFilterData;
  
          estado.field = index;
          estado.text = elemt.nombre;
          estado.value = elemt.valor;
          filter.data.push(estado);
        });
        return filter;
      }
  
    }*/



  /**
   * Componentes del formulario
   */
  FormComponents(data = null): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {

        // Formulario
        nombreFormulario: new TegettFormControl({
          formState: data?.nombreFormulario ?  data?.nombreFormulario : "Mi formulario",
          validatorOrOpts: [Validators.required, DeveloperValidation.ValidarTextLimpio],
          asyncValidator: undefined,
          label: "Nombre Formulario",
          typeField: TypeField.Input, 
          required: true, 
          nameControl: "nombreFormulario",
        }),

        // tipo
        idTipo: new TegettFormControl({
          formState: data?.idTipo ?  data?.idTipo : "1",
          validatorOrOpts: [Validators.required], 
          label: "Tipo",
          typeField: TypeField.List,
          required: true,
          dataTextField: "tipo",
          dataValueField: "idTipo",
          nameControl: "idTipo",
          searchType: "tipos",
        }),

        // icono
        icono: new TegettFormControl({
          formState: data?.icono ?  data?.icono : "fa-duotone fa-table-pivot", 
          validatorOrOpts: [Validators.required],
          asyncValidator: undefined,
          label: "Icono",
          typeField: TypeField.Input, 
          required: true,  
          nameControl: "nombreFormulario",
        }),
           // modulos
           modulo: new TegettFormControl({
            formState:  data?.modulo ?  data?.modulo : "",
            validatorOrOpts: [], 
            label: "Modulo",
            typeField: TypeField.List, 
            dataTextField: "modulo",
            dataValueField: "idModulo",
            nameControl: "modulo",
            searchType: "modulos",
          }),

      },
      []
    );

    return formRecord;
  }

  /*LoadDataControls() {
    return this.pacientesServices.CargarCombosEmpleado<CombosEmpleadoDTO>();
  }*/


  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}

export default class DeveloperValidation {
  static ValidarTextLimpio(control: AbstractControl) {
    return (control: AbstractControl): { [key: string]: any } | null => {

      const value: string = control.value;
      if (!value) {
        return null;
      }
      if (value.trim().length === 0 || /^\d/.test(value)) {
        return { 'invalidText': true };
      }
      return null;
    };
  }
}
