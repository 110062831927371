<kendo-grid 
[rowReorderable]="true" 
[kendoGridReactiveEditing]="createFormGroup" 
[kendoGridBinding]="JsonData.campos"
[sortable]="false" 
(rowReorder)="this.DataEvent.emit()"
[navigable]="true">

    <ng-template kendoGridToolbarTemplate [position]="'top'">


        <button (click)="NuevoCampo()" kendoButton *ngIf="processType != ProcessType.view " rounded="small"
            themeColor="primary" type="button" id="bt-add"><i class="fa-regular fa-plus"></i> Nuevo Campo</button>


    </ng-template>

    <kendo-grid-rowreorder-column [width]="40"></kendo-grid-rowreorder-column>

    <kendo-grid-column *ngVar="formGroupSettingTabCampos?.controls['label'] as ctrl" [field]="ctrl.nameControl"
        [title]="ctrl?.label" editor="text">
    </kendo-grid-column>

    <kendo-grid-column *ngVar="formGroupSettingTabCampos?.controls['nameControl'] as ctrl" [field]="ctrl.nameControl"
        [title]="ctrl?.label" editor="text">
    </kendo-grid-column>


    <kendo-grid-column *ngVar="formGroupSettingTabCampos?.controls['formato'] as ctrl" [field]="ctrl.nameControl"
        [title]="ctrl?.label">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <kendo-dropdownlist [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                [valueField]="ctrl?.dataValueField" [valuePrimitive]="true" [formControl]="ctrl">

            </kendo-dropdownlist>
        </ng-template>


    </kendo-grid-column>



    <kendo-grid-column *ngVar="formGroupSettingTabCampos?.controls['tipo'] as ctrl" [field]="ctrl.nameControl"
        [title]="ctrl?.label">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">


            <div class="row">

                <kendo-dropdownlist [data]="ctrl?.funtionData | async" [textField]="ctrl?.dataTextField"
                    [valueField]="ctrl?.dataValueField" [valuePrimitive]="true" [formControl]="ctrl">
                </kendo-dropdownlist>
            </div>

        </ng-template>

    </kendo-grid-column>

    <kendo-grid-column [width]="100" *ngVar="formGroupSettingTabCampos?.controls['required'] as ctrl"
        [field]="ctrl.nameControl" [title]="ctrl?.label" editor="boolean">
    </kendo-grid-column>


    <kendo-grid-column *ngVar="formGroupSettingTabCampos?.controls['separador'] as ctrl" [field]="ctrl.nameControl"
        [title]="ctrl?.label">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <div class="row">
                <kendo-dropdownlist [data]="JsonData.separadores" [textField]="ctrl?.dataTextField"
                    [valueField]="ctrl?.dataValueField" [valuePrimitive]="true" [formControl]="ctrl">
                </kendo-dropdownlist>
            </div>

        </ng-template>

    </kendo-grid-column>


    <kendo-grid-command-column title="Opciones" [width]="100">
        <ng-template kendoGridCellTemplate let-isNew="isNew">

            <button kendoGridRemoveCommand (click)="this.DataEvent.emit()">
                <i class="fa-regular fa-trash text-red-800"></i> Eliminar
            </button>

        </ng-template>


    </kendo-grid-command-column>
</kendo-grid>