<splus-forms id="empleadosPage" [submit]="submitted" [messageProcess]="messageProcess"
    [showMessageProcess]="showMessageProcess" [FormRecord]="formRecord">

    <form [formGroup]="formRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
        [ngClass]="{'formGroup-read': processType == ProcessType.view}">
        <splus-forms-header [isLoading]="isLoading">

            <splus-forms-main-title [title]="JsonData?.titulo?.titulo" [icon]="JsonData?.titulo?.icono">
            </splus-forms-main-title>

            <splus-forms-toolbar [options]="options">
                <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

                    <ng-container *ngFor="let bt of JsonData?.buttons">

                        <ng-container [ngSwitch]="bt.nombre">
                            <ng-container *ngSwitchCase="'btGuardar'">

                                <button type="Submit" [disabled]="isLoading"
                                    *ngIf="processType == ProcessType.create || processType == ProcessType.edit"
                                    kendoButton rounded="small" themeColor="primary">
                                    <i class="fa-solid fa-floppy-disk"></i> Guardar
                                </button>

                            </ng-container>

                            <ng-container *ngSwitchCase="'btEditar'">

                                <button [disabled]="isLoading" type="button" *ngIf="processType == ProcessType.view"
                                    kendoButton rounded="small" themeColor="primary" (click)="goEdit()">
                                    <i class="fa-solid fa-pencil"></i> Editar
                                </button>
                            </ng-container>
                            <ng-container *ngSwitchCase="'btCancelar'">
                                <button *ngIf="processType == ProcessType.edit" type="button" kendoButton
                                    rounded="small" themeColor="base" (click)="cancelEdit()">
                                    Cancelar
                                </button>
                            </ng-container>
                            <ng-container *ngSwitchCase="'btVolver'">
                                <button *ngIf="processType == ProcessType.view" type="button" kendoButton
                                    rounded="small" themeColor="base" (click)="goback()">
                                    Volver
                                </button>

                            </ng-container>
                            <ng-container *ngSwitchCase="'btLimpiar'">
                                <button *ngIf="processType == ProcessType.create" type="button" kendoButton
                                    rounded="small" themeColor="base" (click)="clearForm()">
                                    Limpiar
                                </button>
                            </ng-container>


                        </ng-container>



                    </ng-container>

                </splus-forms-toolbar-buttons>
            </splus-forms-toolbar>


        </splus-forms-header>

        <splus-forms-fields [processType]="processType" *ngIf="!isLoading">


            <ng-container *ngFor="let separador of JsonData?.separadores">
                <splus-forms-group [title]="separador.nombre" #grupoBasico>

                    <ng-container *ngFor="let campo of JsonData?.campos">
                        <ng-container *ngIf="campo.separador == separador.tag">
                            <ng-container [ngSwitch]="campo.tipo">

                         
                                
                                <ng-container *ngSwitchCase="'Input'">

                                    <ng-container [ngSwitch]="campo.formato">

                                         <!--Solo numeros -->
                                        <ng-container *ngSwitchCase="'string'">
                                            <splus-field *ngVar="f[campo.nameControl] as ctrl"
                                                [splusFormControl]="ctrl">
                                                <kendo-textbox splusInput [splusGroup]="grupoBasico" type="text"
                                                    [id]="ctrl.nameControl" [formControl]="ctrl"> 
                                                
                                                    <ng-template kendoTextBoxPrefixTemplate *ngIf="ctrl.lookup">
                                                        <div class="m-1">
                                                            <i [ngClass]="JsonData.titulo.icono" class="text-primary"></i>
                                                        </div>
                                                        <kendo-textbox-separator></kendo-textbox-separator>
                                                    </ng-template>
                                                
                                                </kendo-textbox>
                                            </splus-field>
                                        </ng-container> 

                                        <!--Solo numeros -->
                                        <ng-container *ngSwitchCase="'integer|int32'">
                                            <splus-field *ngVar="f[campo.nameControl] as ctrl"
                                                [splusFormControl]="ctrl">
                                                <kendo-numerictextbox splusInput [splusGroup]="grupoBasico"
                                                    [id]="ctrl.nameControl" [spinners]="false" format="#"
                                                    [formControl]="ctrl">
                                                
                                                    <ng-template kendoTextBoxPrefixTemplate *ngIf="ctrl.lookup">
                                                        <div class="m-1">
                                                            <i [ngClass]="JsonData.titulo.icono" class="text-primary"></i>
                                                        </div>
                                                        <kendo-textbox-separator></kendo-textbox-separator>
                                                    </ng-template>

                                                </kendo-numerictextbox>
                                            </splus-field>
                                        </ng-container>

                                        <!--Solo numeros -->
                                        <ng-container *ngSwitchCase="'integer|int64'">
                                            <splus-field *ngVar="f[campo.nameControl] as ctrl"
                                                [splusFormControl]="ctrl">
                                                <kendo-numerictextbox splusInput [splusGroup]="grupoBasico"
                                                    [id]="ctrl.nameControl" [spinners]="false" format="#"
                                                    [formControl]="ctrl">
                                                
                                                
                                                    <ng-template kendoTextBoxPrefixTemplate *ngIf="ctrl.lookup">
                                                        <div class="m-1">
                                                            <i [ngClass]="JsonData.titulo.icono" class="text-primary"></i>
                                                        </div>
                                                        <kendo-textbox-separator></kendo-textbox-separator>
                                                    </ng-template>
                                                    
                                                </kendo-numerictextbox>
                                            </splus-field>
                                        </ng-container>

                                    </ng-container>
                                </ng-container>


  
                                <ng-container *ngSwitchCase="'List'">

                                   
                                    <splus-field *ngVar="f[campo.nameControl] as ctrl" [splusFormControl]="ctrl">
                                        <div class="flex">
                                            <kendo-dropdownlist #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
                                                [data]="GetDataApi(campo.nameControl) | async" [textField]="GetProperty(campo.nameControl,'dataTextField')" [valueField]="GetProperty(campo.nameControl,'dataValueField')"
                                                [valuePrimitive]="true">
                                            </kendo-dropdownlist>
                     
                    
                                        </div>
                                        <ng-template splusFieldViewModeTemplate>
                                            {{select["text"]}}
                                        </ng-template>
                    
                    
                                    </splus-field>

                                    
                                </ng-container>
                                
                                

                                <!--Calendario -->
                                <ng-container *ngSwitchCase="'Date'">
                                    <splus-field *ngVar="f.fechaNacimiento as ctrl" [splusFormControl]="ctrl" #fecha> 
                                        <kendo-datepicker #calendario splusInput [navigation]="true" format="dd/MM/yyyy" [max]="ctrl?.maxDate ? ctrl.maxDate : ''" [min]="ctrl?.minDate ? ctrl.minDate : ''"
                                            [formControl]="ctrl" [splusGroup]="grupoBasico" [splusField]="fecha"
                                            [value]="ctrl.value">

                                            <ng-template kendoCalendarNavigationItemTemplate let-title>
                                                {{isNaN(title) ? title : " Ene. " + title.substring( 2, 4 )}}
                                            </ng-template>

                                        </kendo-datepicker>

                                        <ng-template splusFieldViewModeTemplate>
                                            {{calendario.value | SPlusDate}}
                                        </ng-template>

                                        <div class="hit">
                                            {{!calendario.value ? '' : calendario.value | InfoDateFormat}}
                                        </div>


                                    </splus-field>
                                </ng-container>

                                <!--checkbox -->
                                <ng-container *ngSwitchCase="'CheckBox'"> 

                                    <splus-field class="ml-2 flex items-center" *ngVar="f[campo.nameControl] as ctrl" [splusFormControl]="ctrl"
                                    [viewModeDisable]="true" labelCustom="">
                
                                    <input #ctrlCheck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox
                                        [formControl]="ctrl" [splusGroup]="grupoBasico" [id]="ctrl.nameControl" />
                
                                    <ng-template splusFieldViewModeTemplate>
                                        <splus-checkbox-info [checked]="ctrlCheck?.checked">
                                        </splus-checkbox-info>
                                    </ng-template>
                
                                    <label [for]="ctrl.nameControl" class="select-none text-slate-500 dark:text-slate-400 text-sm"> {{ctrl?.label}}</label>
                
                                </splus-field>


                                    
                                </ng-container>





                                <!-- <ng-container *ngSwitchDefault>
                                no se ha definido este tipo de control {{campo.formato}}

                            </ng-container>-->
                            </ng-container>
                        </ng-container>

                    </ng-container>


                </splus-forms-group>

            </ng-container>

 
        </splus-forms-fields>

    </form>
</splus-forms>

<div>
 
</div>